/*
 * @Author: f
 * @Date: 2023-03-16 16:01:29
 * @Description: file content
 * @LastEditors: yangyangli
 * @LastEditTime: 2023-08-08 16:13:18
 */
import type { Result, IPage } from './model/result'
import https, { ContentType, Method, RequestParams } from "@/utils/https";

import {FellowTravelerVo, ExtraRiskVo, InsuredVo, DocListVo, FileListVo, ATDListVo, BenefListVo, FundListVo, MainRiskVo, CarDataVo, HomeDataVo, PolicyHolderVo, MainDriverVo,  GetPolicyDetailsVo, GetLisPolicyDetailsOutVo, GetPolicyDetailsOutVo, GetPolicyDocumentsVo, GetPolicyDocumentsOutVo, GetPolicyListInVo, GetPolicyListOutVo, CustomerOpenAccountInVo, CustomerOpenAccountOutVo, CustomerQueryBindOutListVo, SysCustomerVo, sysOauthVo } from './model/syscustomerModel'
// import { UrlWithStringQuery } from 'url';


/** 寿险保单详情接口   根据等于对象属性值**/
export const syscustomerGetLisPolicyDetails = (params: GetPolicyDetailsVo) => {
  return https().request<any,Result<GetLisPolicyDetailsOutVo>>({
   // url: '/vi/syscustomer/getLisPolicyDetails',
    url: '/vi/syscustomer/getNewLisPolicyDetails',
    method:  Method.POST,
    headers: { "Content-Type": ContentType.json },
    data: params
  });
};
/** 财险保单详情接口   根据等于对象属性值**/
export const syscustomerGetPolicyDetails = (params: GetPolicyDetailsVo) => {
  return https().request<any,Result<GetPolicyDetailsOutVo>>({
   // url: '/vi/syscustomer/getPolicyDetails',
    url: '/vi/syscustomer/getHandlePolicyDetails',
    method:  Method.POST,
    headers: { "Content-Type": ContentType.json },
    data: params
  });
};
/** 文件清单接口   根据等于对象属性值**/
export const syscustomerGetPolicyDocuments = (params: GetPolicyDocumentsVo) => {
  return https().request<any,Result<Array<GetPolicyDocumentsOutVo>>>({
    url: '/vi/syscustomer/getPolicyDocuments',
    method:  Method.POST,
    headers: { "Content-Type": ContentType.json },
    data: params
  });
};
/** 保单列表接口（财险寿险共用）   根据等于对象属性值**/
export const syscustomerGetPolicyList = (params: GetPolicyListInVo) => {
  return https().request<any,Result<Array<GetPolicyListOutVo>>>({
    url: '/vi/syscustomer/getPolicyList',
    method:  Method.POST,
    headers: { "Content-Type": ContentType.json },
    data: params
  });
};
/** 开户申请接口（财险寿险证券共用）   根据等于对象属性值**/
export const syscustomerOpenAccount = (params: CustomerOpenAccountInVo) => {
  return https().request<any,Result<Array<CustomerOpenAccountOutVo>>>({
    url: '/vi/syscustomer/openAccount',
    method:  Method.POST,
    headers: { "Content-Type": ContentType.json },
    data: params
  });
};
/** app绑定查询   根据等于对象属性值**/
export const syscustomerQueryBind = (params: void) => {
  return https().request<any,Result<Array<CustomerQueryBindOutListVo>>>({
    url: '/vi/syscustomer/queryBind',
    method:  Method.POST,
    headers: { "Content-Type": ContentType.json, isEncypt : '1'},
    data: params,
  });
};
/** 客户信息保存/更新接口   根据等于对象属性值**/
export const syscustomerSaveCustomer = (params: SysCustomerVo) => {
  return https().request<any,Result<any>>({
    url: '/vi/syscustomer/saveCustomer',
    method:  Method.POST,
    headers: { "Content-Type": ContentType.json },
    data: params
  });
};

/** 保单列表确定开户解接口**/
export const saveOrUpdateCustomer = (params: SysCustomerVo) => {
  return https().request<any, Result<any>>({
    url: '/vi/syscustomer/saveOrUpdateCustomer',
    method: Method.POST,
    headers: { "Content-Type": ContentType.json },
    data: params
  });
};

export const sysOauthTokenCode = (url:string) => {
  return https().request<any, Result<any>>({
    url: url,
    method: Method.POST,
    headers: { "Content-Type": ContentType.json, isEncypt: '1' },
  });
};
