
/*
 * @Author: 
 * @Date: 2022-12-06 16:25:18
 * @Description: file content
 * @LastEditors: f
 * @LastEditTime: 2023-03-10 10:16:00
 */
import type { RouteRecordRaw } from 'vue-router'

const defaultRouter: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'HomeView',
    redirect: '/home',
    component: () => import('@/layout/IndexNormal.vue'),
    children: [
      {
        path: 'home',
        component: () => import('@/views/HomeView.vue'),
        meta: {
          title: '主页',
          module: 'HomeView',
          hidden: false
        }
      },
    ]
  },
  {
    path: '/iconList',
    name: 'IconList',
    component: () => import('@/views/iconList.vue')
  }
]

export default defaultRouter
