/*
 * @Author: f
 * @Date: 2023-01-05 11:02:29
 * @Description: 人寿路由
 * @LastEditors: yangyang 957027919@qq.com
 * @LastEditTime: 2023-01-13 11:32:13
 */
import { t } from '@/locales/tool'
import type { RouteRecordRaw } from 'vue-router'

const insuranceProductRouter: RouteRecordRaw[] = [
  
  // 保险产品-声明页 开始
  {
    path: '/InsuranceProductIndex',
    component: () => import('@/views/insuranceProducts/InsuranceProductIndex.vue'),
    meta: {
      title: 'insuranceProducts.title',
      module: 'InsuranceProductIndex',
      hidden: false
    }
  },
  {
    path: '/InsuranceAgreement',
    component: () => import('@/views/insuranceProducts/InsuranceAgreement.vue'),
    meta: {
      title: 'InsuranceAgreement.title',
      module: 'InsuranceAgreement',
      hidden: false
    }
  },
  {
    path: '/WealthInsuranceAgreement',
    component: () => import('@/views/insuranceProducts/WealthInsuranceAgreement.vue'),
    meta: {
      title: 'wealthInsuranceAgreement.title',
      module: 'WealthInsuranceAgreement',
      hidden: false
    }
  },
  // 保险产品-声明页 结束
]

export default insuranceProductRouter
