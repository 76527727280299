/*
 * @Author: f
 * @Date: 2022-12-06 11:05:47
 * @Description: 网络请求方法
 * @LastEditors: yangyangli
 * @LastEditTime: 2023-08-17 14:43:22
 */
import axios from 'axios';
import { Toast } from 'vant';
import { userStore } from "@/stores/modules/user";
import { getLocale } from '@/locales';
const {
  NODE_ENV,
  VUE_APP_BASE_API = '',
  VUE_APP_BASE_URL,
  VUE_APP_API_CURRENT_VERSION
} = process.env;
const IS_PROD = ['production', 'prod'].includes(NODE_ENV);
const baseURL = IS_PROD ? VUE_APP_BASE_URL + VUE_APP_BASE_API : VUE_APP_BASE_API;
let networkStatus = true;
const https = () => {
  let language = 'tc';
  switch (getLocale()) {
    case 'zh-HK':
      language = 'tc';
      break;
    case 'zh-CN':
      language = 'sc';
      break;
    default:
      break;
  }
  const user = userStore();
  const config = {
    baseURL,
    timeout: 20000,
    headers: {
      "current-version": user.getUserInfo.currentVersion,
      language,
      Authorization: user.getToken
      // Authorization: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbl9pZCI6IkxHMjAyMzA3MjUxNDI2MjU4NDAzNDUyMjM0NTc4OCIsInVzZXJfaWQiOjksInVzZXJfbmFtZSI6IjkiLCJzY29wZSI6WyJhbGwiXSwiZXhwIjoxNjkwMjY3NTg1LCJqdGkiOiI0ZjllMDc0Yy0yY2NhLTQwODktYjk2MC05MzRjNmExOWJiODIiLCJjbGllbnRfaWQiOiJhcGktYWRtaW4ifQ.llAT7qpNtoAgx2tgGMcLQxMI8c2Z6W4AVrByvHJjw2EehgTzRG1j6OQSSszujgcsUjTSn5Xp0Lkc_m397fCgLysSxG3EOH3be91-opxcDFKNKnIwDuDdPsEyGh2XNGBz6uirRoclLSrMyKNShYO8Y7PokmmO6mNjGP4VB-ciw2E'
    }
  };
  /* 初始化axioas */
  const service = axios.create(config);
  const token = user.getToken;
  const isEny = ref('0');
  /* 请求拦截器 */
  service.interceptors.request.use(async config => {
    // do something
    if (config.url.indexOf('oauth') > -1) {
      config.baseURL = IS_PROD ? VUE_APP_BASE_URL + '/auth' : '/auth';
    } else {
      config.baseURL = baseURL;
    }
    if (typeof config.headers?.set === 'function') {
      // @ts-ignore
      if (!config.headers?.isEncypt || config.headers?.isEncypt == '0') {
        //入参加密
        const res = await jsBridge.toEnctypTion({
          data: config.data
        });
        if (res?.data) {
          config.data = {
            data: res.data.replace(/\s/g, "")
          };
        }
        config['key'] = res.keyOrg, config['iv'] = res.iVOrg,
        // @ts-ignore
        config.headers = {
          'encrypt-data': res.encryptData.replace(/\r\n/g, ""),
          "current-version": user.getUserInfo.currentVersion,
          language,
          Authorization: user.getToken,
          'encrypt-flag': 1
        };
      } else {
        isEny.value = '1';
        // @ts-ignore
        config.headers = {
          "current-version": user.getUserInfo.currentVersion,
          language,
          Authorization: user.getToken
        };
      }
    }
    return config;
  }, error => {
    // 拦截接口超时
    const isTimeOut = error.code == 'ECONNABORTED' && error.message.indexOf('timeout') !== -1;
    if (isTimeOut) {
      Toast && Toast.clear();
      Toast && Toast.fail('网络繁忙，请稍后重试');
    }
    // 处理响应失败
    return Promise.reject(error);
  });
  /* 响应拦截器 */
  service.interceptors.response.use(async response => {
    if (!networkStatus) networkStatus = true;
    // do something
    const iv = response.config['iv'] || '';
    const key = response.config['key'] || '';
    if (isEny.value == '0' && response.data.description == "encrypt") {
      await jsBridge.toDecrypt({
        data: response.data,
        keyOrg: key,
        iVOrg: iv
      }).then(res => {
        if (res.code == '101101') {
          // token过期
          user.resetUserInfo();
          jsBridge.toLogin({
            code: res.code
          });
        } else if (res.code == '1011') {
          // 被踢
          user.resetUserInfo();
          jsBridge.crownedOutLogin();
        }
        response.data = res;
      });
    } else {
      isEny.value = '0';
      if (response.data.code == '101101') {
        // token过期
        user.resetUserInfo();
        jsBridge.toLogin({
          code: response.data.code
        });
      } else if (response.data.code == '1011') {
        // 被踢
        user.resetUserInfo();
        jsBridge.crownedOutLogin();
      }
    }
    console.log(response.data, '===============解密');
    return Promise.resolve(response.data);
  }, error => {
    console.log(error, 'error');
    // 只重试一次
    const {
      config: originalRequest
    } = error;
    if (error?.code === 'ECONNABORTED' || error?.code === 'ERR_BAD_RESPONSE' || error?.message === 'Network Error' || error?.message?.includes?.('timeout')) {
      if (originalRequest._retry) {
        jsBridge.networkAnomaly();
      } else {
        originalRequest._retry = true;
        return service.request(originalRequest);
      }
    }
    // 处理响应失败
    return Promise.reject(error);
  });
  return service;
};
export var Method;
(function (Method) {
  Method["GET"] = "GET";
  Method["POST"] = "POST";
  Method["PUT"] = "PUT";
  Method["PATCH"] = "PATCH";
  Method["DELETE"] = "DELETE";
})(Method || (Method = {}));
export var ContentType;
(function (ContentType) {
  ContentType["form"] = "application/x-www-form-urlencoded";
  ContentType["json"] = "application/json; charset=utf-8";
  ContentType["multipart"] = "multipart/form-data";
})(ContentType || (ContentType = {}));
export default https;