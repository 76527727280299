/*
 * @Author: f
 * @Date: 2023-01-05 11:02:29
 * @Description: 人寿路由
 * @LastEditors: Chenx
 * @LastEditTime: 2023-02-17 19:37:20
 */
import { t } from '@/locales/tool'
import type { RouteRecordRaw } from 'vue-router'

const wealthInsuranceRouter: RouteRecordRaw[] = [
  //財險部分
  {
    path: '/wealthIndex',
    name: 'WealthIndex',
    component: () => import('@/views/wealthInsurance/WealthIndex.vue'),
    // redirect: '/WealthHomePage',
    children: [
      {//財險主頁
        path: '/WealthHomePage',
        component: () => import('@/views/wealthInsurance/wealthInsuranceHome/WealthHomePage.vue'),
        meta: {
          title: '',
          module: 'WealthHomePage',
          hidden: false
        },
      },
      {//我的保單 鏈接/未連接
        path: '/WealthPolicyList',
        name: 'WealthPolicyList',
        redirect: '/WealthPolicyList',
        component: () => import('@/views/wealthInsurance/components/IndexBig.vue'),
        children: [
          {
            path: '/WealthPolicyList',
            component: () => import('@/views/wealthInsurance/wealthPolicy/WealthPolicyList.vue'),
            meta: {
              title: 'wealthInsurance.wealthPolicy.policyList',
              module: 'WealthPolicyList',
              hidden: false
            }
          }
        ]
      },
      {//買保險首頁
        path: '/BuyWealthInsuranceHome',
        component: () => import('@/views/wealthInsurance/buyWealthInsurance/BuyWealthInsuranceHome.vue'),
        meta: {
          title: '',
          module: 'BuyWealthInsuranceHome',
          hidden: false
        }
      },
      //我的
      {
        path: '/myOfGeneral',
        redirect: '/myOfGeneral',
        component: () => import('@/views/wealthInsurance/myOfGeneral/components/IndexBig.vue'),
        children: [
          {
            path: '/myOfGeneral',
            component: () => import('@/views/wealthInsurance/myOfGeneral/MyOfGeneral.vue'),
            meta: {
              title: '',
              module: 'myOfGeneral',
              hidden: false
            }
          }
        ]
      },
    ]
  },
  {//保單詳情
    path: '/wealthPolicy',
    name: 'WealthPolicyDetails',
    component: () => import('@/layout/IndexNormal.vue'),
    children: [
      {
        path: 'details',
        component: () => import('@/views/wealthInsurance/wealthPolicy/WealthPolicyDetails.vue'),
        meta: {
          title: 'lifeInsurance.policy.PolicyDetails',
          module: 'WealthPolicy',
          hidden: false
        }
      }
    ]
  },
  {
    path: '/general',
    name: 'generalOfMy',
    redirect: '/myOfGeneral',
    component: () => import('@/layout/IndexNormal.vue'),
    children: [
      {
        path: 'contactUs',
        component: () => import('@/views/wealthInsurance/myOfGeneral/ContactUs.vue'),
        meta: {
          title: 'my.contactUs.navTitle',
          module: 'my',
          hidden: false
        }
      },
      {
        path: 'userAgreement',
        component: () => import('@/views/wealthInsurance/myOfGeneral/UserAgreement.vue'),
        meta: {
          title: 'my.userStatement',
          module: 'my',
          hidden: false
        }
      },
      {
        path: 'privacyPolicy',
        component: () => import('@/views/wealthInsurance/myOfGeneral/PrivacyPolicy.vue'),
        meta: {
          title: 'my.policy.navTitle',
          module: 'my',
          hidden: false
        }
      },
      {
        path: 'collectionStatement',
        component: () => import('@/views/wealthInsurance/myOfGeneral/CollectionStatement.vue'),
        meta: {
          title: 'my.collection.navTitle',
          module: 'my',
          hidden: false
        }
      },
      {
        path: 'wealthCenter',
        component: () => import('@/views/wealthInsurance/myOfGeneral/WealthCenter.vue'),
        meta: {
          title: 'my.wealthManage.navTitle',
          module: 'my',
          hidden: false
        }
      },
      {
        path: 'aboutWL',
        component: () => import('@/views/wealthInsurance/myOfGeneral/AboutWellLink.vue'),
        meta: {
          title: 'my.aboutInfo.aboutWLInsurance',
          module: 'my',
          hidden: false
        }
      }
    ]
  }
]

export default wealthInsuranceRouter
