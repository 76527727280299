/*
 * @Author: f
 * @Date: 2022-12-06 11:39:57
 * @Description: file content
 * @LastEditors: f
 * @LastEditTime: 2022-12-06 12:47:47
 */
import piniaPluginPersist from 'pinia-plugin-persist'
import { createPinia } from 'pinia'
const pinia = createPinia()

pinia.use(piniaPluginPersist)

export default pinia
