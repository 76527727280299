/*
 * @Author: f
 * @Date: 2022-12-05 14:15:27
 * @Description: file content
 * @LastEditors: yangyangli
 * @LastEditTime: 2023-08-21 15:32:15

 */
import { createApp } from 'vue'
import pinia from '@/stores'

import App from './App.vue'
import router from './router'
import { i18n } from './locales/index'
import 'lib-flexible/flexible'
import './permission'

// Toast
import 'vant/es/toast/style'
// Dialog
import 'vant/es/dialog/style'
// Notify
import 'vant/es/notify/style'
// ImagePreview
import 'vant/es/image-preview/style'
// naive ui
import {
  // create naive ui
  create,
  // component
  NButton,
  NCarousel
} from 'naive-ui'

import './styles/css/main.css'
// 调试工具
import VConsole from 'vconsole'
import jsBridge from '@/utils/jsBridge'


const naive = create({
  components: [NButton,NCarousel]
})
// vconsole
// if(process.env.VUE_APP_RUN_MODE !== 'DEVELOPMENT') new VConsole()
new VConsole()
window.onload = () => {
  jsBridge.initJsBridge(() => {
    const app = createApp(App)
    app.use(pinia)
    app.use(router)
    app.use(i18n)
    app.use(naive)
    app.mount('#app')
  })
}