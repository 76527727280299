/*
 * @Author: f
 * @Date: 2022-12-05 16:46:39
 * @Description: file content
 * @LastEditors: f
 * @LastEditTime: 2023-03-06 14:51:37
 */
import { Loading, showLoadingToast, closeToast } from 'vant'
import { isNavigationFailure } from 'vue-router'
import router from './router'
router.beforeEach(async (from, to, next) => {

  // 只要路由发生了变化 要实现loading效果
  const toast = showLoadingToast({
    duration: 0,
    forbidClick: true
  })
  // 设置页面标题
  // console.log(to.meta.title); // 显示的是当前路由的title
  // const title = to.meta?.title
  // document.title = title
  // 防止路由刷新页面消失,hasNewRoutes:返回值是Boolean当有新的路由的时候 值为true
  next()
})

router.afterEach((to, from, failure) => {
  if (isNavigationFailure(failure)) {
    console.log('failed navigation', failure)
  }
  closeToast()
})

// 无须刷新的路由列表
export const notRefreshWhiteList: string[] = [
  '/insuranceLink/BasicInformation',
  '/insuranceLink/GeneralInsuranceBasic',
  '/insuranceLink/SecuritiesAppBasic',
]

