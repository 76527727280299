/*
 * @Author: f
 * @Date: 2023-01-06 11:04:47
 * @Description: file content
 * @LastEditors: yangyangli
 * @LastEditTime: 2023-07-12 10:42:23
 */
import { t } from '@/locales/tool'
import type { RouteRecordRaw } from 'vue-router'

const InsuranceLinkRouter: RouteRecordRaw[] = [
  {
    path: '/insuranceLink',
    name: 'link',
    component: () => import('@/layout/Index.vue'),
    children: [
      // 连结列表
      {
        name: 'LinkLists',
        path: 'LinkLists',
        // component: () => import('@/views/InsuranceLink/LinkLists.vue'),
        component: () => import('@/views/InsuranceLink/LinkListNew.vue'),
        meta: {
          title: 'separateLink.linkLists.title',
          module: 'LinkLists',
          hidden: false
        }
      },
      // 人寿保险连结基本信息
      {
        path: 'BasicInformation',
        component: () => import('@/views/InsuranceLink/insuranceBasicInfo/LifeInsuranceBasic.vue'),
        meta: {
          title: 'separateLink.lifeInsuranceTitle',
          module: 'BasicInformation',
          hidden: false
        }
      },
      // 一般保险连结基本信息
      {
        path: 'GeneralInsuranceBasic',
        component: () => import('@/views/InsuranceLink/insuranceBasicInfo/GeneralInsuranceBasic.vue'),
        meta: {
          title: 'separateLink.insuranceTitle',
          module: 'GeneralInsuranceBasic',
          hidden: false
        }
      },
      // 寿险连结基本信息
      {
        path: 'SecuritiesAppBasic',
        component: () => import('@/views/InsuranceLink/insuranceBasicInfo/SecuritiesAppBasic.vue'),
        meta: {
          title: 'separateLink.securitiesTitle',
          module: 'SecuritiesAppBasic',
          hidden: false
        }
      },
      // 人寿保险连结证件证明
      {
        path: 'LifeCertificate',
        component: () => import('@/views/InsuranceLink/certificateInfo/LifeCertificate.vue'),
        meta: {
          title: 'separateLink.lifeInsuranceTitle',
          module: 'LifeCertificate',
          hidden: false
        }
      },
      // 一般保险连结证件证明
      {
        path: 'GeneralCertificate',
        component: () => import('@/views/InsuranceLink/certificateInfo/GeneralCertificate.vue'),
        meta: {
          title: 'separateLink.insuranceTitle',
          module: 'GeneralCertificate',
          hidden: false
        }
      },
      //证券app连结证件证明
      {
        path: 'SecuritiesCertificate',
        component: () => import('@/views/InsuranceLink/certificateInfo/SecuritiesCertificate.vue'),
        meta: {
          title: 'separateLink.securitiesTitle',
          module: 'SecuritiesCertificate',
          hidden: false
        }
      },
      // 人寿保单
      {
        path: 'LifeAccountLink',
        component: () => import('@/views/InsuranceLink/appAccount/LifeAccountLink.vue'),
        meta: {
          title: 'separateLink.lifeInsuranceTitle',
          module: 'LifeAccountLink',
          hidden: false
        }
      },
      // 一般寿险保单
      {
        path: 'GeneralAccountLink',
        component: () => import('@/views/InsuranceLink/appAccount/GeneralAccountLink.vue'),
        meta: {
          title: 'separateLink.insuranceTitle',
          module: 'GeneralAccountLink',
          hidden: false
        }
      },
      // 人寿验证码验证
      {
        path: 'LifeLinkCode',
        component: () => import('@/views/InsuranceLink/appAccount/LifeLinkCode.vue'),
        meta: {
          title: 'separateLink.lifeInsuranceTitle',
          module: 'LifeLinkCode',
          hidden: false
        }
      },
      // 财险验证码验证
      {
        path: 'GeneralLinkCode',
        component: () => import('@/views/InsuranceLink/appAccount/GeneralLinkCode.vue'),
        meta: {
          title: 'separateLink.insuranceTitle',
          module: 'GeneralLinkCode',
          hidden: false
        }
      },
       // 寿险无联系电话
      {
        path: 'LifelinkNoPhone',
        component: () => import('@/views/InsuranceLink/appAccount/LifelinkNoPhone.vue'),
        meta: {
          title: 'separateLink.lifeInsuranceTitle',
          module: 'LifelinkNoPhone',
          hidden: false
        }
      },
      // 财险无联系电话
      {
        path: 'GenerlLinkNoPhone',
        component: () => import('@/views/InsuranceLink/appAccount/GenerlLinkNoPhone.vue'),
        meta: {
          title: 'separateLink.insuranceTitle',
          module: 'GenerlLinkNoPhone',
          hidden: false
        }
      },
      // 寿险无保单
      {
        path: 'LifeNoPolicyList',
        component: () => import('@/views/InsuranceLink/appAccount/LifeNoPolicyList.vue'),
        meta: {
          title: 'separateLink.lifeInsuranceTitle',
          module: 'LifeNoPolicyList',
          hidden: false
        }
      },
      // 财险无保单
      {
        path: 'GenerlNoPolicyList',
        component: () => import('@/views/InsuranceLink/appAccount/GenerlNoPolicyList.vue'),
        meta: {
          title: 'separateLink.insuranceTitle',
          module: 'GenerlNoPolicyList',
          hidden: false
        }
      },
      //证券App
      {
        path: 'SecuritiesAccountLink',
        component: () => import('@/views/InsuranceLink/appAccount/SecuritiesAccountLinkNew.vue'),
        meta: {
          title: 'separateLink.securitiesTitle',
          module: 'SecuritiesAccountLink',
          hidden: false
        }
      },
      //证券App注册
      {
        path: 'SecuritiesAccountRegister',
        component: () => import('@/views/InsuranceLink/appAccount/SecuritiesAccountRegister.vue'),
        meta: {
          title: 'separateLink.registerApp',
          module: 'SecuritiesAccountRegister',
          hidden: false
        }
      },
      // 人寿 连结成功
      {
        path: 'LifeConnected',
        component: () => import('@/views/InsuranceLink/successLink/LifeConnected.vue'),
        meta: {
          title: 'separateLink.lifeInsuranceTitle',
          module: 'LifeConnected',
          hidden: false
        }
      },
      // 一般寿险连结成功
      {
        path: 'GeneralConnected',
        component: () => import('@/views/InsuranceLink/successLink/GeneralConnected.vue'),
        meta: {
          title: 'separateLink.insuranceTitle',
          module: 'GeneralConnected',
          hidden: false
        }
      },
      // 证券连结成功
      {
        path: 'SecuritiesConnected',
        component: () => import('@/views/InsuranceLink/successLink/SecuritiesConnected.vue'),
        meta: {
          title: 'separateLink.securitiesTitle',
          module: 'SecuritiesConnected',
          hidden: false
        }
      }
    ]
  }
]

export default InsuranceLinkRouter
