/*
 * @Author: f
 * @Date: 2022-12-05 16:27:58
 * @Description: file content
 * @LastEditors: f
 * @LastEditTime: 2023-02-08 16:35:02
 */
/**
 * 多语言配置入口
 */
import { createI18n } from 'vue-i18n'
import { getLanguage } from '../utils/cookies'

// Vant built-in lang
import enUS from 'vant/es/locale/lang/en-US'
import zhCN from 'vant/es/locale/lang/zh-CN'
import zhHK from 'vant/es/locale/lang/zh-HK'

// User defined lang
import enUsLocale from './en_US'
import zhCnLocale from './zh_CN'
import zhHkLocale from './zh_HK'

const messages: any = {
  'zh-CN': {
    ...zhCnLocale,
    ...zhCN
  },
  'zh-HK': {
    ...zhHkLocale,
    ...zhHK
  },
  'en-US': {
    ...enUsLocale,
    ...enUS
  }
}

// 获取本地语言
export const getLocale = () => {
  // 优先从cookies取语言
  const cookieLanguage = getLanguage()
  if (cookieLanguage) {
    document.documentElement.lang = cookieLanguage
    return cookieLanguage
  }

  // 从浏览器对象取语言
  const language = navigator.language.toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      document.documentElement.lang = locale
      return locale
    }
  }

  // 默认中文 zh-HK
  return 'zh-HK'
}

const i18n = createI18n({
  legacy: false,
  locale: getLocale(),
  fallbackLocale: 'zh-HK',
  globalInjection: true,
  messages
})

export { i18n, messages }
