/*
 * @Author: f
 * @Date: 2022-12-05 14:15:27
 * @Description: file content
 * @LastEditors: f
 * @LastEditTime: 2023-02-08 18:03:53
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import notFound from './modules/notFound'

const constantFiles = require.context('./modules', true, /\.ts$/)

let constantModules: Array<RouteRecordRaw> = []
constantFiles.keys().forEach((key) => {
  if (key === './notFound.ts') return
  constantModules = constantModules.concat(constantFiles(key).default)
})

constantModules = constantModules.concat(notFound)

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantModules
})

export function resetRouter() {
  const newRouter = router;
  (router as any).matcher = (newRouter as any).matcher // reset router
}

export default router

