/*
 * @Author: f
 * @Date: 2023-01-29 11:14:01
 * @Description: file content
 * @LastEditors: f
 * @LastEditTime: 2023-02-13 17:31:21
 */
import type { Result, IPage } from './model/result'
import https, { ContentType, Method, RequestParams } from "@/utils/https";

import { CustomerInfoOutVo, CustomerConfirmPhoneCodeInVo, CustomerDelBindInVo, BondOpenAccountInVo, BondOpenAccountOutVo, BondSaveCustomerInVo, CustomerSendPhoneCodeInVo, CustomerSendCodeOutVo, CustomerSaveCode } from './model/securityModel'


/** 驗證碼校驗   根据等于对象属性值**/
export const securityCheckVerifyCode = (params: CustomerConfirmPhoneCodeInVo) => {
  return https().request<any,Result<any>>({
    url: '/vi/security/checkVerifyCode',
    method:  Method.POST,
    headers: { "Content-Type": ContentType.json },
    data: params
  });
};
/** 证券解绑接口   根据等于对象属性值**/
export const securityDelBindCustomer = (params: CustomerDelBindInVo) => {
  return https().request<any,Result<any>>({
    url: '/vi/security/delBindCustomer',
    method:  Method.POST,
    headers: { "Content-Type": ContentType.json },
    data: params
  });
};
/** 开户申请接口   根据等于对象属性值**/
export const securityOpenAccount = (params: BondOpenAccountInVo) => {
  return https().request<any,Result<BondOpenAccountOutVo>>({
    url: '/vi/security/openAccount',
    method:  Method.POST,
    headers: { "Content-Type": ContentType.json },
    data: params
  });
};
/** 客户信息保存/更新接口   根据等于对象属性值**/
export const securitySaveCustomer = (params: BondSaveCustomerInVo) => {
  return https().request<any,Result<CustomerSaveCode>>({
    url: '/vi/security/saveCustomer',
    method:  Method.POST,
    headers: { "Content-Type": ContentType.json },
    data: params
  });
};
/** 证券短信验证码发送接口   根据等于对象属性值**/
export const securitySendPhoneCode = (params: CustomerSendPhoneCodeInVo) => {
  return https().request<any,Result<CustomerSendCodeOutVo>>({
    url: '/vi/security/sendPhoneCode',
    method:  Method.POST,
    headers: { "Content-Type": ContentType.json },
    data: params
  });
};
/** 骏溢短信验证码发送接口 **/
export const junYiSendPhoneCode = (params: CustomerSendPhoneCodeInVo) => {
  return https().request<any,Result<CustomerSendCodeOutVo>>({
    url: '/vi/security/sendJunYiPhoneCode',
    method:  Method.POST,
    headers: { "Content-Type": ContentType.json },
    data: params
  });
};


// 证券绑定手机号获取接口
export const getCustomerInfo = (params: any) => {
  return https().request<any,Result<CustomerInfoOutVo>>({
    url: '/vi/security/getCustomerInfo',
    method:  Method.POST,
    headers: { "Content-Type": ContentType.json },
    data: params
  });
};
