/*
 * @Author: f
 * @Date: 2022-12-05 16:29:28
 * @Description: file content
 * @LastEditors: f
 * @LastEditTime: 2023-02-17 10:13:19
 */
import Cookies from 'js-cookie'

// App
const languageKey = 'language'
// export const getLanguage = () => Cookies.get(languageKey)
// export const setLanguage = (language: string) => Cookies.set(languageKey, language)

export const getLanguage = () => sessionStorage.getItem(languageKey)
export const setLanguage = (language: string) => sessionStorage.setItem(languageKey, language)
