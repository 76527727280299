/*
 * @Author: f
 * @Date: 2022-12-05 16:28:45
 * @Description: file content
 * @LastEditors: yangyangli
 * @LastEditTime: 2023-07-27 16:31:39
 */
const zh_CN = {
  common: {
    my: '我的',
    add: '新增',
    submit: '提交',
    delete: '删除',
    cancel: '取消',
    policy: '保单',
    HKD: '港币',
    USD:'美元',
    RMB: '人民币',
    Monthly: '月缴',
    Quarterly: '季缴',
    SemiAnnual: '半年缴',
    Annually: '年缴',
    Single: '趸缴',
    DirectBilling: '邮寄帐单',
    PremiumDepositAccount: '预缴保费户口',
    BankAccountAutopay: '银行自动转帐',
    AutopaySetupInProgressingle: '建立自动转帐中',
    CreditCardAutopay: '信用卡转帐',
    CreditCardAutopayToken: '信用卡转帐Token',
    ElectronicDirectPayment: '电子直接付款',
    BankTransfer: '银行转帐',
    Cheque: '支票',
    PremiumPaidListingPremium: '满期金缴付清单保费',
    Self: '本人',
    ParentChild: '父母/子女',
    Spouse: '配偶',
    EmployeeEmployer: '僱员/僱主  ',
    GrandParentGrandChild: '祖父母/孙子女',
    Others: '其他',
    Sibling: '兄弟姐妹',
    OwnEstate: '遗产继承人',
    Trustee: '受托人',
    CreditorAndDebtor: '债权人及债务人',
    Charity: '慈善机构',
    PrimaryBeneficiary: '主要受益人',
    SecondaryBeneficiary: '次受益人',
  },
  lifeInsurance: {
    lifeHomePage: {
      title: '主页',
      notice: '本页由立桥人寿保险拥有并运营',
      guaranteeMoudle: '保障推荐',
      theme: '喜盈三年储蓄守护保储蓄守护保储蓄 守护保',
      themeText: '平均每年保证回报高达平均每年保证回报回报',
      checkBtn: '立即查看',
      naveTitle: '尊享系列百万医疗险全新来袭抓紧抓紧紧紧～',
      eventContent: '专为家庭定制给全家贴心的保障，专属家庭医生照顾全家健康，1对1专',
      goHome: '回首页',
      activitiy: '活动与资讯',
      buyInsurance: '买保险',
      load: '拼命加载中～',
      empty: '暂无符合条件的内容',
    },
    policy: {
      policyList: '我的保单',
      tipsOne: '您尚未连结立桥人寿保险相关账户，请按“去',
      tipsTwo: '连结”会去到立桥金服相关页面为您连结相关账户。',
      golink: '去连结',
      insuranceNum: '保单数',
      insuranceNumValue: '6张',
      Insured: '受保人',
      InsuredValue: '3人',
      cardName: '立智保',
      warrantyPeriod: '保障期限',
      no: '保单编号',
      policyFiles: '保单文件',
      person: '受保人',
      PolicyDetails: '保单详情',
      PolicyNo: '保单编号',
      Claim: '理赔',
      Policymodify: '保单更改',
      SettleRenewalPremium: '缴付续期保费',
      BasicInformation: '基本资料',
      PaymentInformation: '付款资料',
      CoverageInformation: '保障资料',
      LevyPayment: '保费征费',
      PolicyValuesInformation: '保单价值资料',
      MaturityPayment: '期满金',
      BeneficiaryInformation: '受益人资料',
      PolicyDocument: '保单文件',
      BasicPlan: '基本计划',
      PolicyStatus: '保单状况',
      PolicyCurrency: '保单货币',
      NameofLifeInsured: '受保人姓名',
      NameofPolicyowner: '权益人姓名',
      PolicyDate: '保单日期',
      CorrespondenceAddress: '通讯地址',
      MobileNo: '流动电话号码',
      OfficeTel: '公司电话号码',
      HomeTel: '住宅电话号码',
      Email: '电邮地址',
      InsuranceConsultant: '保险顾问',
      InsuranceConsultantCode: '保险顾问编号',
      ContactTelephoneNo: '联络电话',
      PaidToDate: '保费缴至日期',
      PaymentMode: '保费缴付频次',
      ModalPremium: '每期保单保费',
      PerPremiumLevy: '每期保费征费',
      PaymentInformationTips: '*如上述保单已有到期保费，或即将生效的保单更改项目，所显示之“每期保单保费”为下一期的每期保费。',
      PremiumPaymentMethod: '保费缴付方式',
      AutomaticPremiumLoan: '自动转账账户',
      LevyPaymentUS: '保费征费（美元）',
      LevyPaid: '已征收（自上一个保单周年日起）',
      TotalLevyPaid: '已征收的总和',
      OutstandingLevy: '欠缴（尚未逞报至保险业监管局）',
      TotalOutstanding: '欠缴 (欠缴的总和)',
      Status: '状况',
      PolicyCoverageTill: '保障日期至',
      PlanLevel: '计划级别',
      AsofDate: '截至日期',
      GuaranteedCashValue: '保证现金价值',
      PremiumDepositAccount: '预缴保费户口结余',
      FuturePremiumDeposit: '备用保费',
      PolicyLoanInterest: '保单贷款及利息',
      MaturityPaymentInstruction: '期满金付款指示',
      BankAccount: '银行帐号',
      NameofBeneficiary: '受益人姓名',
      RelationshipwithLifeInsured: '与受保人关系',
      PercentageofEntitlement: '领取百分比',
      TypeofBeneficiary: '受益人级别',
      ePolicy: '电子保单',
      eNotification: '电子通知书',
      PolicyIllustration: '保单价值预期演示',
      TotalOutstandingLevy: '欠缴 (欠缴的总和)',
      AutomaticPremiumPayment: '自动保费垫缴',
      AccumulatedDividendsInterest: '积存红利及利息',
      TerminalDividend: '终期红利',
      AccumulatedGuaranteedCashCouponsInterest: '积存可支取保证现金及利息',
      SumOfInsured: '投保额',
      SpecialDividend: '特别红利 ',
      AutomaticPremiumLoanInterest: '自动保费垫缴及利息',
      CreditCardNo: '信用卡号码',
      AnnuityPaymentMode: '年金发放方式',
      AnnuityAmount: '每期年金金额',
      BasicPlanFringeBenefitProtection: '基本计划/附加利益保障',
      AnnuityStartDate: '首期年金发放日期',
      ReversionaryBonus: '归原红利',
      VHISCertificationNumber: '自愿医保认可产品编号',
      HomeInsurancePolicyDetails: '家居保险 保单详情',
      Productname: '产品名称',
      PolicyInfo: '保单资料',
      Policyholder: '保单持有人',
      HomeDetails: '家居资料',
      PolicyCover: '保障详情',
      OptionalCover: '自选保障',
      TotalPremium: '保费总额',
      Plan: '计划',
      StartDate: '生效日期',
      EndDate: '届满日期',
      PolicyholderInformation: '保单持有人资料',
      Name: '姓名',
      Gender: '性别',
      MobileNumber852: '手提电话号码 (+852)',
      RiskLocation: '受保地点',
      FloorAreaSqFt: '面积 (平方呎)',
      YearofBuilt: '落成年份',
      PropertyType: '建筑物类型',
      Occupancy: '佔用性质',
      Cover: '保障',
      SumInsured: '保额',
      PolicyExcess: '自选保障',
      HealthInsurancePolicyDetails: '医疗保险 保单详情',
      extraCover: '额外保障',
      PolicyPlan: '保险计划',
      HKIDNumber: '香港身份证号码',
      DateofBirth: '出生日期',
      MaritalStatus: '婚姻状况',
      Address: '地址',
      InsuredDetails: '受保人资料',
      PolicyExcessExtra: '保单自负额',
      Refertopolicyschedule: '参考保单文件',
      Installment: '分期付款',
      MonthlyPremium: '每月保费',
      SinglePayment: '一次性付款',
      TravelInsurancePolicyDetails: '旅游保险 保单详情',
      PolicyType: '保单类别',
      PlanCode: '计划类型',
      Zone: '地区范围',
      PolicyholderInsuredPersonInformation: '保单持有人(受保人)资料',
      TravellerInformation: '同行人士资料',
      PrivateMotorInsurancePolicyDetails: '私家车保险 保单详情',
      DriverDetails: '司机资料',
      VehicleDetails: '车辆资料',
      Occupation: '职业',
      PolicyholderMainDriverInformation: '保单持有人(主要记名司机)资料',
      MainDriverInformation: '主要记名司机资料',
      DrivingOffencePointsinthelast2years: '在过去的2年内曾有违例驾驶扣分纪录?',
      NoClaimDiscountNCD: '无赔偿记录折扣(NCD)',
      DrivingExperienceYears: '驾驶经验(年)',
      NamedDriversInformation: '记名司机资料',
      VehicleInformation: '车辆资料',
      VehicleRegistrationMark: '车辆登记号码',
      ChassisNumber: '车身底盘号码',
      EngineNumber: '引擎号码',
      HirePurchaseOwner: '财务公司名称',
      HirePurchaseValue: '贷款金额',
      YearofManufacture: '出厂年份',
      CarMake: '车厂名称',
      CarModel: '型号',
      CarUsage: '车辆用途',
      statusInvalid: '已失效',
      statusValid: '生效中',
      statusValiding: '投保中',
      insured:'受保人',
      coveredVehicleType: '受保车型',
      premDueDate: '保费到期日',
      annualPremium:'年缴保费',
      MoreThan5Years: '5年以上',
      dialogTitle: '提示',
      dialogContent: '保单详情仅对有效保单开放，其他状态暂不支持查看',
      dialogBtn: '我知道了',
      noFile: '未找到保单文件',
      noFile1: '未找到电子保单',
      noFile2: '此保单没有该类别下可供查阅之通知书',
      noFile3: '未找到保单价值预期演示',
      dialogContentWBT06: '该产品不支持查看详情！',
      sure:'确定',
      AnniversaryNotice: '周年通知书',
      PreservationNotice: '保全通知书',
      RenewalNotice: '续期通知书',
      ClaimNotice: '理赔通知书',
      FinancialReceipt: '财务收据',
      noLisDocIDDes: '该保单暂无此类型文件！',
      loadFail:'文件加载失败，请稍后再试！'
    }
  },
  wealthInsurance: {
    wealthHomePage: {
      notice: '本页由立桥保险拥有并运营',
      lifeClass: '人寿',
      lifeClassTitle: '尊享系列百万医疗险全新来袭抓紧抓紧紧紧～',
      lifeClassContent: '专为家庭定制给全家贴心的保障，专属家庭医生照顾全家健康，1对1专',
      DrivingClass: '驾驶',
      OutTravelClass: '外游',
      ElectricBicycleClass: '电单车',
      HealthyClass: '健康',
      load: '拼命加载中～',
      empty: '暂无符合条件的内容',
      discountsPromotions:'优惠与推广'
    },
    wealthPolicy: {
      policyList: '我的保单',
      goHome: '回首页',
      tipsOne: '您尚未连结立桥保险相关账户，请按“去',
      tipsTwo: '连结”会去到立桥金服相关页面为您连结相关账户。',
      golink: '去连结',
      tips:'提示',
      details:'本保单经由中介安排，如有查询，',
      contact:'请联络你的中介人',
      confirm:'确认'
    },
    policy: {
      detailTitle: '保单资料',
      productName: '产品名称',
      no: '保单编号',
      effectDate: '生效日期',
      overdueData: '届满日期'
    }
  },
  my: {
    contactUs: {
      navTitle: '联系我们',
      canHelp: '有什么可以帮您',
      solveProblem: '专属客服人员解决您的问题'
    },
    policy: {
      navTitle: '私隐政策与保安',
      privacyPolicy: '隐私政策',
      siyinPolicy: '私隐政策',
      personalPrivacyPolicy: '个人资料私隐政策'
    },
    collection: {
      navTitle: '个人信息收集声明'
    },
    userStatement: '使用条款',
    networkInfoTitle: '网络安全须知',
    disclaimerTitle: '免责声明',
    wellLinkProTitle: '立桥国际证券有限公司',
    wellLinkTitle: '立桥证券有限公司',
    aboutInfo: {
      about: '关于',
      aboutWL: '关于立桥',
      aboutWLInsurance: '关于立桥保险',
      aboutWLLife: '关于立桥人寿',
      versionInfo: '版本信息',
      aboutTip:'© 2024立桥金融集团版权所有不得转载'
    },
    title: '联络我们',
    address: '地址',
    workTime: '办公时间',
    serviceTel: '客户服务热线',
    email: '电邮',
    net: '网址',
    hi: 'Hi～',
    user: '亲爱的立桥用户',
    backToHome: '回首页',
    canHelp: '有什么可以帮您',
    solveProblem: '专属客服人员解决您的问题',
    lifeInsurance: '人寿保险',
    normalInsurance: '一般保险',
    wellLink: '立桥证券有限公司',
    article: '网站使用条款',
    wealthManage: {
      navTitle: '财富管理中心',
      bgTitle1: '立桥人寿',
      bgTitle2: '财富管理中心',
      bgTip: '为客户提供贴心保险咨询服务。',
      WLLife: '立桥人寿',
      branchInfo: '分店信息'
    },
    // about: '关于',
    // aboutWL:'关于立桥',
    msgCenter: {
      navTitle: '消息中心',
      msgList: '消息列表',
      msgDetails: '消息详情'
    },
    myOfLife: {
      effectPolicyNum: '有效保单数',
      myIntegral: '我的积分',
      zhang: '张',
      ren: '人',
    }
  },
  separateLink: {
    lifeInsuranceTitle: '人寿保险保单连结',
    insuranceTitle: '一般保险保单连结',
    securitiesTitle: '证券账户连结',
    registerApp: '开通立桥国际证券APP账号',
    serviceAuthorization: '服务授权条款',
    nextBtn: '下一步',
    previousBtn: '上一步',
    warn: '请选择您要连结的账户',
    linkLists: {
      title: '连结账户',
      titleDes: '您可连结立桥金融集团旗下各公司账户',
      lifeInsurance: '立桥人寿保险',
      insurance: '立桥保险',
      securities: '立桥国际证券',
      noConnected: '未连结',
      insConnected: '已连结，可继续连结其他账户',
      securitiesConnected: '已连结，可解除连结后连结其他账户',
      goLink: '去连结'
    },
    basicInformation: {
      titleDes: '请输入你的基本个人资料让我们确认身份',
      enLastName: '英文姓氏',
      enName: '英文名字',
      enNameGeneral: '英文姓名',
      birthday: '出生日期',
      gender: '性别',
      select: '请选择',
      input: '请输入',
      warnEnLastName: '请输入英文姓氏',
      warnEnName: '请输入英文名字',
      warnEnNameGeneral: '请输入英文姓名',
      dateChoose: '请选择',
      warnBirthDay: '请选择出生日期',
      warnGender: '请选择性别'
    },
    idLink: {
      titleDes: '请输入购买保单时所使用的身份证明文件',
      titleDes1: '请输入开户时所使用的身份证明文件',
      HKIdCard: '香港身份证',
      PRC: '中华人民共和国',
      PRCIdCard: '居民身份证',
      passports: '各国护照',
      MacaoIdCard: '澳门身份证',
      idCardTitle: '证件号码（省去符号）',
      placeholder: '例如号码A123456(7),请输入A1234567',
      placeholderElse: '请输入您的证件号码'
    },
    policyList: {
      titleDes: '亲爱的客户，已为您找到以下保单！',
      titleNoDate: '亲爱的客户，我们未能找到您的保单！',
      securitiesDes: '若您想更换其他手机号连结证券APP，请修改手机号',
      lifeDes: '根据您输入的资料，我们找不到人寿保险保单。请检查资料是否正确（例如：选择的证件是否与买单时提供的一致），阁下可返回前页进行修改',
      genDes: '根据您输入的资料，我们找不到一般保险保单。请检查资料是否正确（例如：选择的证件是否与买单时提供的一致）。阁下可返回前页进行修改。',
      backBtn: '返回',
      saveBtn: '保存',
      agree: '我已阅读并同意',
      agreeServe: '《服务授权条款》',
      agreement: '《服务协议》',
      agreeClause: '《私隐保护政策》',
      and: '和',
      linkBtn: '连结',
      plaPhone: '请输入手机号',
      plaCode: '请输入验证码',
      verificationCode: '验证码',
      warnVerification: '请输入验证码',
      warnVerificationLength:'请输入正确的验证码',
      getVerificationCode: '获取验证码',
      recode: '重新获取',
      please: '请选择',
      pleaseCard: '请选择护照国家',
      endBtn: '结束',
      finishBtn: '完成',
      lifeConnected: '立桥人寿保险已连结',
      generalConnected: '立桥保险（一般保险）已连结',
      securityConnected: '立桥国际证券已连结',
      securitySuccess: '按完成开始体验立桥e家',
      tips: '提示',
      unregistered: '未找到立桥国际证券账户',
      unregistered1: '即将为您跳转注册',
      sureBtn: '确认',
      linkEd: '已连结',
      linkEdText: '您的证券APP账户已连结成功，若需要连结其他账户，请按继续解除连结后再绑定新账户',
      continueBtn: '继续',
      registerText: '请填写以下信息，以供注册',
      registerTextPhone: '立桥国际证券登入账户',
      registerVersCode: '您短讯中的验证码',
      noMore: '没有更多了',
      callText: '如有疑问，请致电 ',
      unLinkedSuccess: '解除证券账户连结成功！',
      to: '及',
      warnPhone: '请输入正确的手机号码',
      warnIdNum: '您未输入证件号码',
      errorIdNum: '请输入正确的证件号码',
      verCode: '请输入完整验证码',
      radioRead: '请阅读并勾选对应协议',
      lifeCodeTips1: "亲爱的客户，根据您提供的资料",
      lifeCodeTips2: "为您找到以下与保单相关的电话。",
      selectTel: '请选择任一电话号码获取验证码',
      code: '输入短信中的验证码',
      linkTips: '如以上电话号码不正确，请',
      call: '联系我们',
      NoPhone: '亲爱的用户，根据您提供的资料发现您的保单尚未预留移动电话，请',
      NoPhoneTips:'，补充移动电话后再次尝试连结您的保单！',
    },
  },

  //保险产品
  insuranceProducts: {
    title: '保险产品',
    name: '登记即享免费意外保障',
    insuranceIntroduce: '新客户礼遇新客户礼遇新客户礼遇新客户礼遇新客户礼遇新客户礼遇'
  },
  InsuranceAgreement: {
    title: '立桥人寿保险',
    tipsOne: '您即将浏览的保险产品由立桥人寿保险有限公司（「立桥人寿」）提供，若您对于下一个页面所包含的信息及内容有任何疑问，',
    link: '可联系「立桥人寿」',
    tipsTwo: '进行咨询。',
    open: '即将为您打开手机浏览器跳转至对应产品页面。',
    btnContent: '我已知晓，并继续',
    linkMethods: '联系电话',
    phone: '呼叫 +85228307500',
    cancelBtn: '取消'
  },
  wealthInsuranceAgreement: {
    title: '立桥保险',
    tipsOne: '您即将浏览的保险产品由立桥保险有限公司([立桥保险])提供，若您对于下一个页面所包含的资讯及内容有任何疑问，',
    link: '可联系[立桥保险]',
    tipsTwo: '进行咨询。',
    open: ' 即将为您打开手机浏览器跳转至对应产品页面。',
    btnContent: '我已知晓，并继续',
    linkMethods: '联系电话',
    phone: '呼叫 +8522848888',
    cancelBtn: '取消'
  },
  iconList: {
    title: '服务',
    titleDiscount: '优惠及产品',
    titleLifeInsurance: '人寿保险',
    titleInsurance: '一般保险',
    titleSecurities: '证券',
    titleSyetemAndMessage: '系统与消息'
  },
  discountZone: {
    title: '优惠专区'
  },
  discountTabData: [{
    src: './image/discount/itemfirst.png',
    heading: '尊享系列百万医疗险全新来袭抓紧抓紧紧紧～',
    subheading: '专为家庭定制给全家贴心的保障，专属家庭医生照顾全家健康，1对1专'
  }, {
    src: './image/discount/itemfirst.png',
    heading: '新来袭抓紧抓紧紧紧～',
    subheading: '专为家庭定制给全家贴心的保障，专属家庭医生照顾全家健康，1对1专'
  }, {
    src: './image/discount/itemfirst.png',
    heading: '百万医疗险全新来袭抓紧抓紧紧紧～',
    subheading: '专为家庭定制给全家贴心的保障，专属家庭医生照顾全家健康，1对1专'
  }, {
    src: './image/discount/itemfirst.png',
    heading: '系列百万医疗险全新来袭抓紧抓紧紧紧～',
    subheading: '专为家庭定制给全家贴心的保障，专属家庭医生照顾全家健康，1对1专'
  }],
  notFound: {
    pageLoading: '拼命加载中～',
    noConditionTips: '暂无符合条件的内容',
    networkError: '网络异常，请刷新重试',
    noLogin: '暂未登录， ',
    toLogin: '去登录'
  }
}

export default zh_CN
