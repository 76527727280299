/*
 * @Author: yangyang 957027919@qq.com
 * @Date: 2023-01-16 20:22:46
 * @LastEditors: yangyang 957027919@qq.com
 * @LastEditTime: 2023-01-31 09:57:40
 * @FilePath: \work\app-frontend-viewcode\src\apis\syscodeApi.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import type { Result, IPage } from './model/result'
import https, { ContentType, Method, RequestParams } from '@/utils/https'

import { 码表下拉选择, BaseCodeVo } from './model/syscodeModel'

/** 查询码表下拉选择   根据等于对象属性值**/
export const syscodeCodeSelectList = (params: 码表下拉选择) => {
  return https().request<any, Result<Array<BaseCodeVo>>>({
    url: '/sys/syscode/codeSelectList',
    method: Method.POST,
    headers: { 'Content-Type': ContentType.json },
    data: params
  })
}
