/*
 * @Author: f
 * @Date: 2022-12-06 16:25:18
 * @Description: file content
 * @LastEditors: linyy
 * @LastEditTime: 2023-01-11 14:27:12
 */
import type { RouteRecordRaw } from 'vue-router'

const discountZoneRouter: RouteRecordRaw[] = [
  {
    path: '/discountZone',
    name: 'DiscountZone',
    children:[
        {
          path: 'index',
          name: 'DiscountZone',
          component: () => import('@/views/discountZone/discountZone.vue')
        }
    ] 
  }
]

export default discountZoneRouter
