/*
 * @Description: 大厅-我的路由
 * @Author: Chenx
 * @Date: 2023-01-06 14:13:07
 * @LastEditors: Chenx
 * @LastEditTime: 2023-03-08 15:46:52
 */

import { t } from '@/locales/tool'
import type { RouteRecordRaw } from 'vue-router'

const MyOfHallRouter: RouteRecordRaw[] = [
  {
    path: '/myOfHall',
    name: 'myOfHall',
    component: () => import('@/layout/IndexNormal.vue'),
    children: [
      {
        path: '/contactUs',
        component: () => import('@/views/myOfHall/ContactUs.vue'),
        meta: {
          title: 'my.contactUs.navTitle',
          module: 'my',
          hidden: false
        }
      },
      {
        path: '/userAgreement',
        component: () => import('@/views/myOfHall/UserAgreement.vue'),
        meta: {
          title: 'my.userStatement',
          module: 'my',
          hidden: false
        }
      },
      {
        path: '/privacyPolicy',
        component: () => import('@/views/myOfHall/PrivacyPolicy.vue'),
        meta: {
          title: 'my.policy.siyinPolicy',
          module: 'my',
          hidden: false
        }
      },
      // 网路安全须知
      {
        path: '/networkSecurityInfo',
        component: () => import('@/views/myOfHall/NetworkSecurityInfo.vue'),
        meta: {
          title: 'my.networkInfoTitle',
          module: 'my',
          hidden: false
        }
      },
       // 个人资料私隐政策
      {
        path: '/personalPrivacyPolicy',
        component: () => import('@/views/myOfHall/PersonalPrivacyPolicy.vue'),
        meta: {
          title: 'my.policy.personalPrivacyPolicy',
          module: 'my',
          hidden: false
        }
      },
       // 立桥国际证券有限公司 协议服务内容
      {
        path: '/WellLinkPro',
        component: () => import('@/views/myOfHall/WellLinkPro.vue'),
        meta: {
          title: 'my.wellLinkProTitle',
          module: 'my',
          hidden: false
        }
      },
       // 立桥证券有限公司 协议服务内容
      {
        path: '/wellLinkInfo',
        component: () => import('@/views/myOfHall/WellLink.vue'),
        meta: {
          title: 'my.wellLinkTitle',
          module: 'my',
          hidden: false
        }
      },
       // 免责声明
      {
        path: '/disclaimerInfo',
        component: () => import('@/views/myOfHall/DisclaimerInfo.vue'),
        meta: {
          title: 'my.disclaimerTitle',
          module: 'my',
          hidden: false
        }
      },
      {
        path: '/collectionStatement',
        component: () => import('@/views/myOfHall/CollectionStatement.vue'),
        meta: {
          title: 'my.collection.navTitle',
          module: 'my',
          hidden: false
        }
      },
      {
        path: '/wealthCenter',
        name: 'wealthCenter',
        component: () => import('@/views/myOfHall/WealthCenter.vue'),
        meta: {
          title: 'my.wealthManage.navTitle',
          module: 'my',
          hidden: false
        }
      },
      {
        path: '/aboutOfHall',
        name: 'aboutOfHall',
        component: () => import('@/views/myOfHall/AboutIndex.vue'),
        meta: {
          title: 'my.aboutInfo.about',
          module: 'my',
          hidden: false
        }
      },
      {
        path: '/aboutWL',
        name: 'aboutWL',
        component: () => import('@/views/myOfHall/AboutWellLink.vue'),
        meta: {
          title:  'my.aboutInfo.aboutWL',
          module: 'my',
          hidden: false
        }
      },
      {
        path: '/messageType',
        name: 'messageType',
        component: () => import('@/views/myOfHall/MessageTypeList.vue'),
        meta: {
          title: 'my.msgCenter.navTitle',
          module: 'my',
          hidden: false
        }
      },
      // 证券-服务授权静态页
      {
        path: '/serviceAuthorization',
        component: () => import('@/views/InsuranceLink/appAccount/serviceAuthorization.vue'),
        meta: {
          title: 'separateLink.serviceAuthorization',
          module: 'serviceAuthorization',
          hidden: false
        }
      }
    ]
  },
  {
    path: '/messageList',
    name: 'messageList',
    component: () => import('@/views/myOfHall/MessageList.vue'),
    children: [],
    meta: {
      title: 'my.msgCenter.msgList',
      module: 'my',
      hidden: false
    }
  },
  {
    path: '/messageDetails',
    name: 'messageDetails',
    component: () => import('@/views/myOfHall/MessageDetails.vue'),
    children: [],
    meta: {
      title: 'my.msgCenter.msgDetails',
      module: 'my',
      hidden: false
    }
  }
  // 大厅-我的-相关路由结束@cx
]

export default MyOfHallRouter
