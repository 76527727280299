/*
 * @Author: f
 * @Date: 2022-12-05 18:16:40
 * @Description: 工具类
 * @LastEditors: f
 * @LastEditTime: 2023-04-12 18:31:36
 */
export namespace tool {
  export class dateProcesses {
    /**
     * 格式化日期
     * @param date 日期对象
     * @param fmt 格式化字符串
     * @returns {string}
     */
    static formatDate(date: Date, fmt = 'yyyy-MM-dd') {
      const o: Record<string, any> = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        S: date.getMilliseconds()
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }

      for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
        }
      }
      return fmt
    }
  }

  export class valideRule {
    // 校验手机号
    static checkPhone(phone: string, code = '852') {
      if (phone) {
        // 校验11位有效数字
        if (code == '86') {
          if (/^1\d{10}$/.test(phone)) {
            return true
          }
        } else if ((code == '852' || code == '853')) {
          // if (/^(5|6|8|9)\d{7}$/.test(phone)) {
          //   return true
          // }
          if (phone.length == 8) {
            return true
          }
          //
        } else if (/^\d/.test(phone) && phone.length <= 11) {
          //
          return true
        }
      }

      return false
    }

    // 校验邮箱
    static checkEmail(email: string) {
      const reg = /^[a-zA-Z0-9._-]+[a-zA-Z0-9]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/

      if (reg.test(email)) return true

      return false
    }

    // 校验密码
    static checkPassword(password: string) {
      const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,18}$/

      if (reg.test(password)) return true

      return false
    }

    // 中文校验
    static checkChinessWord(word: string, length = 0) {
      const reg = /^[\u4e00-\u9fa5]$/
      if (reg.test(word) && (length == 0 || word.length == length)) return true

      return false
    }
    // 英文名校验校验
    static checkEnWord(word: string, length = 0) {
      const reg = /^[a-zA-Z]$/
      if (reg.test(word) && (length == 0 || word.length == length)) return true

      return false
    }

    // 验证码
    static valideCode(code: string) {
      const reg = /^\d{4,8}$/
      if (reg.test(code)) return true

      return false
    }

    static changeA210(no: string): number {
      return no.charCodeAt(0) - 55
    }

    //香港身份证校验
    static checkIdCardHK(idno) {
      let chk = 0
      if (!idno) {
        return true
      }
      const numTest100 = /^[a-zA-Z]{1,2}[\d]{6}[A\d]{1}$/
      if (!numTest100.test(idno)) {
        // myAlert(I18NMsg("G0000012401"));
        return false
      }
      if (idno.length == 9) {
        chk =
          this.changeA210(idno.substr(0, 1)) * 9 +
          this.changeA210(idno.substr(1, 1)) * 8 +
          idno.substr(2, 1) * 7 +
          idno.substr(3, 1) * 6 +
          idno.substr(4, 1) * 5 +
          idno.substr(5, 1) * 4 +
          idno.substr(6, 1) * 3 +
          idno.substr(7, 1) * 2
      } else if (idno.length == 8) {
        chk =
          36 * 9 +
          this.changeA210(idno.substr(0, 1)) * 8 +
          idno.substr(1, 1) * 7 +
          idno.substr(2, 1) * 6 +
          idno.substr(3, 1) * 5 +
          idno.substr(4, 1) * 4 +
          idno.substr(5, 1) * 3 +
          idno.substr(6, 1) * 2
      } else {
        // myAlert(I18NMsg("G0000012400"));
        return false
      }

      let c1 = idno.substr(idno.length - 1, 1)
      if ('A' == c1) {
        c1 = 10
      }

      if ('A' == c1) {
        c1 = 10
      }

      let c
      if (c1 == 0) {
        c = chk % 11
      } else {
        c = 11 - (chk % 11)
      }

      if (c != c1) {
        // myAlert(I18NMsg("G0000012401"));
        return false
      }
      return true
    }

    /**
     * 判斷是否是閏年
     * @param str string
     * @returns boolean
     */
    static LeapYear(str: string): boolean {
      const year = parseInt(str)
      return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0
    }

    /**
     * 计算身份证校验码
     * 兰军 2005-7-2 17:06
     * 原理:
     * ∑(a[i]*W[i]) mod 11 ( i = 2, 3, ..., 18 )(1)
     * "*" 表示乘号
     * i--------表示身份证号码每一位的序号，从右至左，最左侧为18，最右侧为1。
     * a[i]-----表示身份证号码第 i 位上的号码
     * W[i]-----表示第 i 位上的权值 W[i] = 2^(i-1) mod 11
     * 计算公式 (1) 令结果为 R
     * 根据下表找出 R 对应的校验码即为要求身份证号码的校验码C。
     * R 0 1 2 3 4 5 6 7 8 9 10
     * C 1 0 X 9 8 7 6 5 4 3 2
     * X 就是 10，罗马数字中的 10 就是 X
     * 15位转18位中,计算校验位即最后一位
     */
    static GetVerifyBit(id) {
      let result
      let nNum =
        id.charAt(0) * 7 +
        id.charAt(1) * 9 +
        id.charAt(2) * 10 +
        id.charAt(3) * 5 +
        id.charAt(4) * 8 +
        id.charAt(5) * 4 +
        id.charAt(6) * 2 +
        id.charAt(7) * 1 +
        id.charAt(8) * 6 +
        id.charAt(9) * 3 +
        id.charAt(10) * 7 +
        id.charAt(11) * 9 +
        id.charAt(12) * 10 +
        id.charAt(13) * 5 +
        id.charAt(14) * 8 +
        id.charAt(15) * 4 +
        id.charAt(16) * 2

      nNum = nNum % 11
      switch (nNum) {
        case 0:
          result = '1'
          break
        case 1:
          result = '0'
          break
        case 2:
          result = 'X'
          break
        case 3:
          result = '9'
          break
        case 4:
          result = '8'
          break
        case 5:
          result = '7'
          break
        case 6:
          result = '6'
          break
        case 7:
          result = '5'
          break
        case 8:
          result = '4'
          break
        case 9:
          result = '3'
          break
        case 10:
          result = '2'
          break
      }
      return result
    }

    ///居民身份证校验
    /**
     * 严格校验身份证号码
     * 兰军 2005-7-2 17:05
     * 公民身份号码是特征组合码，
     * 由十七位数字本体码和一位数字校验码组成。
     * 排列顺序从左至右依次为：六位数字地址码，八位数字出生日期码，
     * 三位数字顺序码和一位数字校验码。顺序码的奇数分给男性，偶数分给女性。
     * 校验码是根据前面十七位数字码，按照ISO7064:1983.MOD11-2校验码计算出来的检验码。
     */
    static checkIdCard(idCard, SysDate: string | Date) {
      let SystemDate = SysDate
      let year: number
      let month: number
      let day: number
      if (SystemDate) {
        year = parseInt((SystemDate as string).substring(0, 4))
        month = parseInt((SystemDate as string).substring(5, 7))
        day = parseInt((SystemDate as string).substring(8, 10))
      } else {
        SystemDate = new Date()
        year = SystemDate.getFullYear()
        month = SystemDate.getMonth() + 1
        day = SystemDate.getDate()
      }
      let yyyy //年
      let mm //月
      let dd //日
      let birthday //生日
      let sex //性别
      const id = idCard
      const id_length = id.length
      if (id_length == 0) {
        // myAlert(I18NMsg('M0000064462'))
        return false
      }
      if (id_length != 15 && id_length != 18) {
        // myAlert(I18NMsg('M0000064463'))
        return false
      }
      if (id_length == 15) {
        for (let i = 0; i < id_length; i++) {
          if (isNaN(idCard.charAt(i))) {
            // myAlert(I18NMsg('M0000064464'))
            return false
          }
        }
        yyyy = '19' + id.substring(6, 8)
        mm = id.substring(8, 10)
        dd = id.substring(10, 12)
        if (mm > 12 || mm <= 0) {
          // myAlert(I18NMsg('M0000064465'))
          return false
        }
        if (dd > 31 || dd <= 0) {
          // myAlert(I18NMsg('M0000064466'))
          return false
        }
        //4,6,9,11月份日期不能超过30
        if ((mm == 4 || mm == 6 || mm == 9 || mm == 11) && dd > 30) {
          // myAlert(I18NMsg('M0000064466'))
          return false
        }
        //判断2月份
        if (mm == 2) {
          if (this.LeapYear(yyyy)) {
            if (dd > 29) {
              // myAlert(I18NMsg('M0000064466'))
              return false
            }
          } else {
            if (dd > 28) {
              // myAlert(I18NMsg('M0000064466'))
              return false
            }
          }
        }
      } else {
        for (let i = 0; i < id_length - 1; i++) {
          if (isNaN(idCard.charAt(i))) {
            // myAlert(I18NMsg('M0000064467'))
            return false
          }
        }
        if (isNaN(idCard.charAt(17)) && idCard.charAt(17) != 'X' && idCard.charAt(17) != 'x') {
          // myAlert(I18NMsg('M0000064468'))
          return false
        }
        if ((idCard.indexOf('X') > 0 && idCard.indexOf('X') != 17) || (idCard.indexOf('x') > 0 && idCard.indexOf('x') != 17)) {
          // myAlert(I18NMsg('L0000020815'))
          return false
        }
        yyyy = id.substring(6, 10)
        if (yyyy > year || yyyy < 1900) {
          // myAlert(I18NMsg('M0000064470'))
          return false
        }
        mm = id.substring(10, 12)
        if (mm > 12 || mm <= 0) {
          // myAlert(I18NMsg('M0000064465'))
          return false
        }
        if (yyyy == year && mm > month) {
          // myAlert(I18NMsg('M0000064465'))
          return false
        }
        dd = id.substring(12, 14)
        if (dd > 31 || dd <= 0) {
          // myAlert(I18NMsg('M0000064466'))
          return false
        }
        //4,6,9,11月份日期不能超过30
        if ((mm == 4 || mm == 6 || mm == 9 || mm == 11) && dd > 30) {
          // myAlert(I18NMsg('M0000064466'))
          return false
        }
        //判断2月份
        if (mm == 2) {
          if (this.LeapYear(yyyy)) {
            if (dd > 29) {
              // myAlert(I18NMsg('M0000064466'))
              return false
            }
          } else {
            if (dd > 28) {
              // myAlert(I18NMsg('M0000064466'))
              return false
            }
          }
        }
        if (yyyy == year && mm == month && dd > day) {
          // myAlert(I18NMsg('M0000064466'))
          return false
        }
        if (id.charAt(17) == 'x' || id.charAt(17) == 'X') {
          if ('x' != this.GetVerifyBit(id) && 'X' != this.GetVerifyBit(id)) {
            // myAlert(I18NMsg('M0000064468'))
            return false
          }
        } else {
          if (id.charAt(17) != this.GetVerifyBit(id)) {
            // myAlert(I18NMsg('M0000064468'))
            return false
          }
        }
      }
      return true
    }

    /**
     * 澳门身份证校验
     * @param idCard
     */
    static checkIdCardMO(idCard: string) {
      if (!idCard) return false
      const reg = /^[1|5|7][0-9]{6}[0-9A-Z]$/
      return reg.test(idCard)
    }

    /**
     * 护照校验
     * @param idCard
     */
    static checkPassportMO(idCard: string) {
      if (!idCard) return false
      const reg = /^[0-9A-Za-z]{1,20}$/
      return reg.test(idCard)
    }
  }
}
