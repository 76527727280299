/*
 * @Author: f
 * @Date: 2022-12-08 16:51:18
 * @Description: file content
 * @LastEditors: f
 * @LastEditTime: 2023-03-03 16:04:49
 */
import { defineStore } from 'pinia'
interface UserInfoType  {
  mobileAreaCode?: string
  mobilePhone?: string
  currentVersion?:string
  securityAccountLink?: string
}
export const userStore = defineStore('user', {
  state() {
    return {
      token: '',
      userInfo: {}
    }
  },
  getters: {
    getToken(state: { token: string }): string {
      return state.token
    },
    getUserInfo(state: { userInfo: Object }): UserInfoType {
      return state.userInfo
    }
  },
  actions: {
    setToken(value: string) {
      this.token = value
    },
    setUserInfo(value: Object) {
      this.userInfo = value
    },
    resetUserInfo() {
      this.token = ''
      this.userInfo = {}
    }
  },
  persist: {
    enabled: true,
    strategies: [{ storage: localStorage, paths: ['token', 'userInfo'] }]
  }
})
