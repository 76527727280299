/*
 * @Author: yangyangli
 * @Date: 2023-05-19 10:59:12
 * @Description: file content
 * @LastEditors: yangyangli
 * @LastEditTime: 2023-07-27 15:37:30
 */
/*
 * @Author: f
 * @Date: 2023-01-05 11:02:29
 * @Description: 人寿路由
 * @LastEditors: yangyangli
 * @LastEditTime: 2023-06-25 17:52:00
 */
import { t } from '@/locales/tool'
import type { RouteRecordRaw } from 'vue-router'

const lifeInsuranceRouter: RouteRecordRaw[] = [
  //壽險部分
  {
    path: '/LifeIndex',
    name: 'LifeIndex',
    component: () => import('@/views/lifeInsurance/LifeIndex.vue'),
    // redirect: '/LifeHomePage',
    children: [
      {
        //壽險主頁
        path: '/LifeHomePage',
        component: () => import('@/views/lifeInsurance/lifeInsuranceHome/LifeHomePage.vue'),
        meta: {
          title: '',
          module: 'LifeHomePage',
          hidden: false
        }
      },
      { // 我的保單 鏈接/未連接
        path: '/PolicyList',
        name: 'PolicyList',
        redirect: '/PolicyList',
        component: () => import('@/views/lifeInsurance/components/IndexBig.vue'),
        children: [
          {
            path: '/PolicyList',
            component: () => import('@/views/lifeInsurance/policy/PolicyList.vue'),
            meta: {
              title: 'lifeInsurance.policy.policyList',
              module: 'PolicyList',
              hidden: false
            }
          }
        ]
      },
      {
        //買保險首頁
        path: '/BuyInsuranceHome',
        component: () => import('@/views/lifeInsurance/buyInsurance/BuyInsuranceHome.vue'),
        meta: {
          title: '',
          module: 'BuyInsuranceHome',
          hidden: false
        }
      },
      //我的
      {
        path: '/myOfLife',
        name: 'myOfLife',
        redirect: '/myOfLife',
        component: () => import('@/views/lifeInsurance/myOfLife/components/IndexBig.vue'),
        children: [
          {
            path: '/myOfLife',
            component: () => import('@/views/lifeInsurance/myOfLife/MyOfLife.vue'),
            meta: {
              title: '',
              module: 'life',
              hidden: false
            }
          }
        ]
      },
    ]
  },
  {
    path: '/life',
    name: 'lifeOfMy',
    redirect: '/myOfLife',
    component: () => import('@/layout/IndexNormal.vue'),
    children: [
      {
        path: 'contactUs',
        component: () => import('@/views/lifeInsurance/myOfLife/ContactUs.vue'),
        meta: {
          title: 'my.contactUs.navTitle',
          module: 'my',
          hidden: false
        }
      },
      {
        path: 'userAgreement',
        component: () => import('@/views/lifeInsurance/myOfLife/UserAgreement.vue'),
        meta: {
          title: 'my.userStatement',
          module: 'my',
          hidden: false
        }
      },
      {
        path: 'privacyPolicy',
        component: () => import('@/views/lifeInsurance/myOfLife/PrivacyPolicy.vue'),
        meta: {
          title: 'my.policy.navTitle',
          module: 'my',
          hidden: false
        }
      },
      {
        path: 'collectionStatement',
        component: () => import('@/views/lifeInsurance/myOfLife/CollectionStatement.vue'),
        meta: {
          title: 'my.collection.navTitle',
          module: 'my',
          hidden: false
        }
      },
      {
        path: 'wealthCenter',
        component: () => import('@/views/lifeInsurance/myOfLife/WealthCenter.vue'),
        meta: {
          title: 'my.wealthManage.navTitle',
          module: 'my',
          hidden: false
        }
      },
      {
        path: 'aboutWL',
        component: () => import('@/views/lifeInsurance/myOfLife/AboutWellLink.vue'),
        meta: {
          title: 'my.aboutInfo.aboutWLLife',
          module: 'my',
          hidden: false
        }
      }
    ]
  },
  {
    //保單詳情
    path: '/lifeInsurance/policy',
    name: 'lifeInsurance/Policy',
    component: () => import('@/layout/IndexNormal.vue'),
    children: [
      {
        path: 'details',
        component: () => import('@/views/lifeInsurance/policy/PolicyDetails.vue'),
        meta: {
          title: 'lifeInsurance.policy.PolicyDetails',
          module: 'PolicyDetails',
          hidden: false
        }
      }
    ]
  },
  {
    //保單詳情--电子通知书
    path: '/lifeInsurance/policy/eNotification',
    component: () => import('@/views/lifeInsurance/policy/PolicyFolder/eNotification.vue'),
    meta: {
      title: '',
      module: 'eNotification',
      hidden: false
    }
  },
]

export default lifeInsuranceRouter
