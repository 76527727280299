import "core-js/modules/es.array.push.js";
import router from '@/router';
const {
  NODE_ENV,
  VUE_APP_RUN_MODE
} = process.env;
/**
 * 路由跳转公共push方法
 * @param params RouteLocationRaw类型， router.push() 传参一样
 *
 * @returns router的promise对象
 */
export const routerPush = params => {
  if (jsBridge.isApp() || jsBridge.isAndroid || jsBridge.isiOS) {
    const routerInfo = router.resolve(params);
    let url = routerInfo.fullPath;
    // // ios真机本地测试配置
    // if (VUE_APP_RUN_MODE == 'SIMULATION' && jsBridge.isiOS) {
    //   url = localStorage.getItem('location_origin') + '/#' + url
    // }
    jsBridge.openWebview({
      url
    });
  } else {
    return router.push(params);
  }
};
// 获取assets静态资源
export const getAssetsFile = url => {
  return require(`@/assets/${url}`);
};
/**
 * px转rem方法
 * @param px string
 * @returns string
 */
export const pxToRem = px => {
  return px / 37.5 + 'rem';
};
/**
 * 国际編碼集合
 */
export const countryCodes = [{
  code: 'HK',
  chname: '中國香港(中華人民共和國)'
}, {
  code: 'Mainland',
  chname: '中國大陸'
}, {
  code: 'MO',
  chname: '中國澳門(中華人民共和國)'
}, {
  code: 'LY',
  chname: '利比亞'
}, {
  code: 'MA',
  chname: '摩洛哥'
}, {
  code: 'MC',
  chname: '摩納哥'
}, {
  code: 'MD',
  chname: '摩爾多瓦共和國'
}, {
  code: 'ME',
  chname: '黑山'
}, {
  code: 'MF',
  chname: '法屬聖馬丁島'
}, {
  code: 'MG',
  chname: '馬達加斯加'
}, {
  code: 'MH',
  chname: '馬紹爾群島'
}, {
  code: 'MK',
  chname: '馬其頓'
}, {
  code: 'ML',
  chname: '馬里'
}, {
  code: 'MM',
  chname: '緬甸'
}, {
  code: 'MN',
  chname: '蒙古'
}, {
  code: 'MP',
  chname: '北馬里亞納群島'
}, {
  code: 'MQ',
  chname: '馬提尼克島'
}, {
  code: 'MR',
  chname: '毛裏塔尼亞'
}, {
  code: 'MS',
  chname: '蒙特塞拉特島'
}, {
  code: 'MT',
  chname: '馬耳他'
}, {
  code: 'MU',
  chname: '毛里求斯'
}, {
  code: 'MV',
  chname: '馬爾代夫'
}, {
  code: 'MW',
  chname: '馬拉維'
}, {
  code: 'MX',
  chname: '墨西哥'
}, {
  code: 'MY',
  chname: '馬來西亞'
}, {
  code: 'MZ',
  chname: '莫桑比克'
}, {
  code: 'NA',
  chname: '納米比亞'
}, {
  code: 'NC',
  chname: '新喀里多尼亞'
}, {
  code: 'NE',
  chname: '尼日爾共和國'
}, {
  code: 'NF',
  chname: '諾福克群島'
}, {
  code: 'NG',
  chname: '尼日里亞'
}, {
  code: 'NI',
  chname: '尼加拉瓜'
}, {
  code: 'NL',
  chname: '荷蘭'
}, {
  code: 'NO',
  chname: '挪威'
}, {
  code: 'NP',
  chname: '尼泊爾'
}, {
  code: 'NR',
  chname: '瑙魯島'
}, {
  code: 'NU',
  chname: '紐埃島'
}, {
  code: 'NZ',
  chname: '新西蘭'
}, {
  code: 'OM',
  chname: '阿曼'
}, {
  code: 'PA',
  chname: '巴拿馬'
}, {
  code: 'PE',
  chname: '秘魯'
}, {
  code: 'PF',
  chname: '法屬波利尼西亞'
}, {
  code: 'PG',
  chname: '巴布亞新幾內亞'
}, {
  code: 'PH',
  chname: '菲律賓'
}, {
  code: 'PK',
  chname: '巴基斯坦'
}, {
  code: 'PL',
  chname: '波蘭'
}, {
  code: 'PM',
  chname: '聖皮埃爾島和密克隆島'
}, {
  code: 'PN',
  chname: '皮特凱恩'
}, {
  code: 'PR',
  chname: '波多黎各島'
}, {
  code: 'PS',
  chname: '巴勒斯坦'
}, {
  code: 'PT',
  chname: '葡萄牙'
}, {
  code: 'PW',
  chname: '帕勞'
}, {
  code: 'PY',
  chname: '巴拉圭'
}, {
  code: 'QA',
  chname: '卡塔爾'
}, {
  code: 'RE',
  chname: '留尼汪島'
}, {
  code: 'RO',
  chname: '羅馬尼亞'
}, {
  code: 'RS',
  chname: '塞爾維亞'
}, {
  code: 'RU',
  chname: '俄羅斯'
}, {
  code: 'RW',
  chname: '盧旺達'
}, {
  code: 'SA',
  chname: '沙地阿拉伯'
}, {
  code: 'SB',
  chname: '所羅門群島'
}, {
  code: 'SC',
  chname: '塞舌爾'
}, {
  code: 'SD',
  chname: '蘇丹'
}, {
  code: 'SE',
  chname: '瑞典'
}, {
  code: 'SG',
  chname: '新加坡'
}, {
  code: 'SH',
  chname: '聖赫勒拿島'
}, {
  code: 'SI',
  chname: '斯洛文尼亞'
}, {
  code: 'SJ',
  chname: '斯瓦爾巴群島及揚馬延島'
}, {
  code: 'SK',
  chname: '斯洛伐克共和國'
}, {
  code: 'SL',
  chname: '塞拉里昂'
}, {
  code: 'SM',
  chname: '聖馬力諾'
}, {
  code: 'SN',
  chname: '塞內加爾'
}, {
  code: 'SO',
  chname: '索馬利亞'
}, {
  code: 'SR',
  chname: '蘇利南'
}, {
  code: 'SS',
  chname: '南蘇丹'
}, {
  code: 'ST',
  chname: '聖多美和普林西比'
}, {
  code: 'SV',
  chname: '薩爾瓦多'
}, {
  code: 'SX',
  chname: '荷屬聖馬丁島'
}, {
  code: 'SY',
  chname: '敘利亞'
}, {
  code: 'SZ',
  chname: '斯威士蘭'
}, {
  code: 'TC',
  chname: '特克斯及凱科斯群島'
}, {
  code: 'TD',
  chname: '乍得'
}, {
  code: 'TF',
  chname: '法屬南部地區'
}, {
  code: 'TG',
  chname: '多哥'
}, {
  code: 'TH',
  chname: '泰國'
}, {
  code: 'TJ',
  chname: '塔吉克斯坦'
}, {
  code: 'TK',
  chname: '托克勞群島'
}, {
  code: 'TL',
  chname: '東帝汶'
}, {
  code: 'TM',
  chname: '土庫曼斯坦'
}, {
  code: 'TN',
  chname: '突尼西亞'
}, {
  code: 'TO',
  chname: '湯加'
}, {
  code: 'TR',
  chname: '土耳其'
}, {
  code: 'TT',
  chname: '千里達和多巴哥'
}, {
  code: 'TV',
  chname: '圖瓦盧'
}, {
  code: 'TW',
  chname: '中國台灣'
}, {
  code: 'TZ',
  chname: '坦桑尼亞'
}, {
  code: 'UA',
  chname: '烏克蘭'
}, {
  code: 'UG',
  chname: '烏干達'
}, {
  code: 'UM',
  chname: '美屬邊疆群島'
}, {
  code: 'US',
  chname: '美國'
}, {
  code: 'UY',
  chname: '烏拉圭'
}, {
  code: 'UZ',
  chname: '烏茲別克斯坦'
}, {
  code: 'VA',
  chname: '梵蒂岡'
}, {
  code: 'VC',
  chname: '聖文森特和格林納丁斯'
}, {
  code: 'VE',
  chname: '委內瑞拉'
}, {
  code: 'VG',
  chname: '英屬維京群島'
}, {
  code: 'VI',
  chname: '美屬維京群島'
}, {
  code: 'VN',
  chname: '越南'
}, {
  code: 'VU',
  chname: '瓦努阿圖'
}, {
  code: 'WF',
  chname: '瓦利斯群島及富圖納群島'
}, {
  code: 'WS',
  chname: '薩摩亞'
}, {
  code: 'YE',
  chname: '也門'
}, {
  code: 'YT',
  chname: '馬約特島'
}, {
  code: 'ZA',
  chname: '南非'
}, {
  code: 'ZM',
  chname: '贊比亞'
}, {
  code: 'ZW',
  chname: '津巴布韋'
}, {
  code: 'AD',
  chname: '安道爾'
}, {
  code: 'AE',
  chname: '阿拉伯聯合酋長國'
}, {
  code: 'AF',
  chname: '阿富汗'
}, {
  code: 'AG',
  chname: '安提瓜 和 巴布達'
}, {
  code: 'AI',
  chname: '安圭拉島'
}, {
  code: 'AL',
  chname: '阿爾巴尼亞'
}, {
  code: 'AM',
  chname: '亞美尼亞'
}, {
  code: 'AO',
  chname: '安哥拉'
}, {
  code: 'AQ',
  chname: '南極洲'
}, {
  code: 'AR',
  chname: '阿根廷'
}, {
  code: 'AS',
  chname: '美薩摩亞'
}, {
  code: 'AT',
  chname: '奧地利'
}, {
  code: 'AU',
  chname: '澳大利亞'
}, {
  code: 'AW',
  chname: '阿魯巴島'
}, {
  code: 'AX',
  chname: '阿蘭群島'
}, {
  code: 'AZ',
  chname: '阿塞拜疆'
}, {
  code: 'BA',
  chname: '波斯尼亞及黑塞哥維那'
}, {
  code: 'BB',
  chname: '巴比多斯/巴巴多斯'
}, {
  code: 'BD',
  chname: '孟加拉'
}, {
  code: 'BE',
  chname: '比利時'
}, {
  code: 'BF',
  chname: '布基納法索'
}, {
  code: 'BG',
  chname: '保加利亞'
}, {
  code: 'BH',
  chname: '巴林'
}, {
  code: 'BI',
  chname: '布隆迪'
}, {
  code: 'BJ',
  chname: '貝寧'
}, {
  code: 'BL',
  chname: '聖巴托洛繆島'
}, {
  code: 'BM',
  chname: '百慕達'
}, {
  code: 'BN',
  chname: '文萊'
}, {
  code: 'BO',
  chname: '玻利維亞'
}, {
  code: 'BQ',
  chname: '博內爾、聖尤斯泰修斯及沙巴島'
}, {
  code: 'BR',
  chname: '巴西'
}, {
  code: 'BS',
  chname: '巴哈馬'
}, {
  code: 'BT',
  chname: '不丹'
}, {
  code: 'BV',
  chname: '布維群島'
}, {
  code: 'BW',
  chname: '博茨瓦納'
}, {
  code: 'BY',
  chname: '白俄羅斯'
}, {
  code: 'BZ',
  chname: '百利茲'
}, {
  code: 'CA',
  chname: '加拿大'
}, {
  code: 'CC',
  chname: '可可斯群島/科科斯群島'
}, {
  code: 'CD',
  chname: '剛果(金)'
}, {
  code: 'CF',
  chname: '中非共和國'
}, {
  code: 'CG',
  chname: '剛果(布)'
}, {
  code: 'CH',
  chname: '瑞士'
}, {
  code: 'CI',
  chname: '科特迪瓦'
}, {
  code: 'CK',
  chname: '庫克群島'
}, {
  code: 'CL',
  chname: '智利'
}, {
  code: 'CM',
  chname: '喀麥隆'
}, {
  code: 'CN',
  chname: '中國大陸'
}, {
  code: 'CO',
  chname: '哥倫比亞'
}, {
  code: 'CR',
  chname: '哥斯達黎加'
}, {
  code: 'CU',
  chname: '古巴'
}, {
  code: 'CV',
  chname: '佛得角'
}, {
  code: 'CW',
  chname: '庫拉索'
}, {
  code: 'CX',
  chname: '聖誕島'
}, {
  code: 'CY',
  chname: '塞浦路斯'
}, {
  code: 'CZ',
  chname: '捷克'
}, {
  code: 'DE',
  chname: '德國'
}, {
  code: 'DJ',
  chname: '吉布提'
}, {
  code: 'DK',
  chname: '丹麥'
}, {
  code: 'DM',
  chname: '多米尼加'
}, {
  code: 'DO',
  chname: '多明尼加共和國'
}, {
  code: 'DZ',
  chname: '阿爾及利亞'
}, {
  code: 'EC',
  chname: '厄瓜多爾'
}, {
  code: 'EE',
  chname: '愛沙尼亞'
}, {
  code: 'EG',
  chname: '埃及'
}, {
  code: 'EH',
  chname: '西撒哈拉'
}, {
  code: 'ER',
  chname: '厄立特里亞'
}, {
  code: 'ES',
  chname: '西班牙'
}, {
  code: 'ET',
  chname: '埃塞俄比亞'
}, {
  code: 'FI',
  chname: '芬蘭'
}, {
  code: 'FJ',
  chname: '斐濟'
}, {
  code: 'FK',
  chname: '福克蘭群島(馬爾維納斯群島)'
}, {
  code: 'FM',
  chname: '密克羅尼西亞聯邦'
}, {
  code: 'FO',
  chname: '法羅群島'
}, {
  code: 'FR',
  chname: '法國'
}, {
  code: 'GA',
  chname: '加蓬'
}, {
  code: 'GB',
  chname: '英國'
}, {
  code: 'GD',
  chname: '格林納達'
}, {
  code: 'GE',
  chname: '格魯吉亞'
}, {
  code: 'GF',
  chname: '法屬圭亞那'
}, {
  code: 'GG',
  chname: '根西島'
}, {
  code: 'GH',
  chname: '加納'
}, {
  code: 'GI',
  chname: '直布羅陀'
}, {
  code: 'GL',
  chname: '格陵蘭島'
}, {
  code: 'GM',
  chname: '剛比亞'
}, {
  code: 'GN',
  chname: '幾內亞'
}, {
  code: 'GP',
  chname: '瓜德羅普島'
}, {
  code: 'GQ',
  chname: '赤道幾內亞'
}, {
  code: 'GR',
  chname: '希臘'
}, {
  code: 'GS',
  chname: '南喬治亞島及南桑威奇群島'
}, {
  code: 'GT',
  chname: '危地馬拉'
}, {
  code: 'GU',
  chname: '關島'
}, {
  code: 'GW',
  chname: '幾內亞比紹'
}, {
  code: 'GY',
  chname: '圭亞那'
}, {
  code: 'HM',
  chname: '赫爾特及麥克唐納群島'
}, {
  code: 'HN',
  chname: '洪都拉斯'
}, {
  code: 'HR',
  chname: '克羅地亞'
}, {
  code: 'HT',
  chname: '海地'
}, {
  code: 'HU',
  chname: '匈牙利'
}, {
  code: 'ID',
  chname: '印尼'
}, {
  code: 'IE',
  chname: '愛爾蘭'
}, {
  code: 'IL',
  chname: '以色列'
}, {
  code: 'IM',
  chname: '萌島'
}, {
  code: 'IN',
  chname: '印度'
}, {
  code: 'IO',
  chname: '英屬印度洋地區'
}, {
  code: 'IQ',
  chname: '伊拉克'
}, {
  code: 'IR',
  chname: '伊朗'
}, {
  code: 'IS',
  chname: '冰島'
}, {
  code: 'IT',
  chname: '意大利'
}, {
  code: 'JE',
  chname: '澤西島'
}, {
  code: 'JM',
  chname: '牙買加'
}, {
  code: 'JO',
  chname: '約旦'
}, {
  code: 'JP',
  chname: '日本'
}, {
  code: 'KE',
  chname: '肯尼亞(肯雅)'
}, {
  code: 'KG',
  chname: '吉爾吉斯斯坦'
}, {
  code: 'KH',
  chname: '柬埔寨'
}, {
  code: 'KI',
  chname: '基里巴斯'
}, {
  code: 'KM',
  chname: '科摩羅'
}, {
  code: 'KN',
  chname: '聖克里斯托弗和尼維斯聯邦'
}, {
  code: 'KP',
  chname: '朝鮮(北韓)'
}, {
  code: 'KR',
  chname: '韓國'
}, {
  code: 'KW',
  chname: '科威特'
}, {
  code: 'KY',
  chname: '開曼群島'
}, {
  code: 'KZ',
  chname: '哈薩克斯坦'
}, {
  code: 'LA',
  chname: '老撾(寮國)'
}, {
  code: 'LB',
  chname: '黎巴嫩'
}, {
  code: 'LC',
  chname: '聖盧西亞'
}, {
  code: 'LI',
  chname: '列支敦士登'
}, {
  code: 'LK',
  chname: '斯里蘭卡'
}, {
  code: 'LR',
  chname: '利比利亞'
}, {
  code: 'LS',
  chname: '萊索托'
}, {
  code: 'LT',
  chname: '立陶宛'
}, {
  code: 'LU',
  chname: '盧森堡'
}, {
  code: 'LV',
  chname: '拉脫維亞'
}, {
  code: 'XK',
  chname: '科索沃'
}];