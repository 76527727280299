/*
 * @Author: f
 * @Date: 2022-12-12 15:16:29
 * @Description: file content
 * @LastEditors: f
 * @LastEditTime: 2023-02-09 14:54:33
 */

import { Locale as vantLocale } from 'vant'
import { getLocale, i18n, messages } from '.'

// 导出全局t方法
export const t = (str: string): string => {
  /* @ts-ignore */
  return i18n.global.t(str)
}

// 设置语言
export const setLocale = () => {
  const CURRENT_LANG = getLocale()
  // vant 国际化
  vantLocale.use(CURRENT_LANG, messages[CURRENT_LANG])

  // i18n 国际化
  const { locale } = i18n.global
  locale.value = CURRENT_LANG
}
