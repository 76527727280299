/*
 * @Author: yangyang 957027919@qq.com
 * @Date: 2022-12-30 17:34:28
 * @LastEditors: f
 * @LastEditTime: 2023-02-17 10:29:21
 * @FilePath: \work\app-frontend-viewcode\src\router\modules\notFound.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import type { RouteRecordRaw } from 'vue-router'

const notFoundRouter: RouteRecordRaw[] = [
  {
    path: '/notFound',
    component: () => import('@/layout/IndexNormal.vue'),
    children: [
      {
        path: '/pageLoading',
        name: 'pageLoading',
        component: () => import('@/views/notFound/PageLoading.vue'),
        meta: {
          title: '',
          module: 'pageLoading'
        }
      },
      {
        path: '/networkAnomaly',
        name: 'networkAnomaly',
        component: () => import('@/views/notFound/NetworkAnomaly.vue'),
        meta: {
          title: '',
          module: 'networkAnomaly'
        }
      },
      {
        path: '/noConditionsPage',
        name: 'noConditionsPage',
        component: () => import('@/views/notFound/NoConditionsPage.vue'),
        meta: {
          title: '',
          module: 'noConditionsPage'
        }
      },
    ]
  },
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: '/noConditionsPage'
  }
]

export default notFoundRouter
