/*
 * @Author: f
 * @Date: 2022-12-05 16:28:45
 * @Description: file content
 * @LastEditors: f
 * @LastEditTime: 2022-12-05 16:28:46
 */
const en_US = {
  home: {
    title: 'Home'
  },
  example: {
    title: 'Test'
  }
}

export default en_US
