import _defineProperty from "/Users/Helen/project/wl-e-home/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
/*
 * @Author: f
 * @Date: 2022-12-29 10:48:31
 * @Description: app原生与js交互的封装公共方法
 * @LastEditors: yangyangli
 * @LastEditTime: 2023-07-12 15:30:39
 */
import { userStore } from '../stores/modules/user';
class JsBridge {
  constructor() {
    _defineProperty(this, "isAndroid", false);
    _defineProperty(this, "isiOS", false);
    _defineProperty(this, "nativeEvents", ['appCallJs']);
    const u = navigator.userAgent;
    this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  }
  /**
   * 链接app
   * @param callback
   * @returns WebViewJavascriptBridge对象
   */
  connectWebViewJavascriptBridge(callback) {
    if (this.isAndroid) {
      if (window.WebViewJavascriptBridge) {
        callback(window.WebViewJavascriptBridge);
      } else {
        document.addEventListener('WebViewJavascriptBridgeReady', function () {
          callback(window.WebViewJavascriptBridge);
        }, false);
      }
      return;
    }
    if (this.isiOS) {
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge);
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
      }
      window.WVJBCallbacks = [callback];
      const WVJBIframe = document.createElement('iframe');
      WVJBIframe.style.display = 'none';
      WVJBIframe.src = 'https://__bridge_loaded__';
      document.documentElement.appendChild(WVJBIframe);
      setTimeout(function () {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
    }
  }
  // app调用js方法

  /**
   * 初始化app调用本地方法
   */
  initNativeEvent() {
    // this.nativeEvents.forEach((event) => this.registerHandler(event))
  }
  /**
   * 初始化jsbridge方法
   */
  initJsBridge(readyCallback) {
    console.warn('JsBridge初始化');
    if (process.env.VUE_APP_RUN_MODE == 'DEVELOPMENT') {
      // 开发环境执行回调
      console.warn('JsBridge 初始化失败');
      return readyCallback();
    }
    // 调用注册方法
    this.connectWebViewJavascriptBridge(bridge => {
      if (this.isAndroid) {
        bridge.init(function (message, responseCallback) {
          //console.log('JS got a message', message);
          responseCallback(message);
        });
      }
      if (bridge) {
        console.warn('JsBridge 初始化完成');
        window.$bridge = bridge;
        if (readyCallback) readyCallback();
      }
    });
  }
  /**
   * 判断是否在app内部
   * @returns boolean
   */
  isApp() {
    // return this.isAndroid || this.isiOS
    return !!window.$bridge;
  }
  /**
   * 原生调用js方法
   * @param event 事件名称
   * @returns promise对象
   */
  registerHandler(event, cb) {
    //APP主动调用js方法，预留
    if (window.$bridge) {
      // console.log('registerHandler:', event)
      window.$bridge.registerHandler(event, (data, responseCallback) => {
        responseCallback('native 传过来的是：' + data); //js返回APP回调
        try {
          const res = JSON.parse(data);
          console.log('原生解析用户信息:', res);
          // 重新登录
          if (event == 'appCallJsRelogin') {
            const store = userStore();
            store.setToken(res.data.token);
            store.setUserInfo(res.data);
          }
          // console.log(event + '事件返回值：' + data) //js返回APP回调
          cb(res.data);
        } catch (error) {
          console.log(error);
          cb(null);
        }
      });
    }
  }
  /**
   * js 调用app方法
   *
   * @param event 事件名称
   * @param params 入参
   * @return 返回promise对象
   */
  callNative(event, params) {
    return new Promise((resolve, reject) => {
      // console.log('入参：', params)
      if (window.WebViewJavascriptBridge) {
        window.WebViewJavascriptBridge.callHandler(event, params, response => {
          try {
            const res = JSON.parse(response);
            if (res.status == 0) {
              if (typeof res.data == 'string') {
                res.data = JSON.parse(res.data);
              }
              console.log('app返回值：', res);
              resolve(res.data);
            } else {
              // this.toast({ text: res.message })
              reject(res.message);
            }
          } catch (error) {
            reject(error);
          }
        });
      } else {
        console.warn('调用失败：未在app环境内调用app接口');
        // reject(new Error('调用失败'))
      }
    });
  }
  /**
   * 打开相册
   */
  openPhoto({
    number
  }) {
    return this.callNative('jsCallApp', {
      method: 'openPhoto',
      number
    });
  }
  /**
   * 返回原生页面
   */
  toPage({
    pageName
  }) {
    this.callNative('jsCallApp', {
      method: 'toPage',
      pageName
    }).then(res => {
      // console.log(res)
    }).catch(error => {
      console.log(error);
    });
  }
  /**
   * 打开pdf文件
   */
  openPdf({
    pdfName,
    pdfUrl,
    shareFlag = true,
    type
  }) {
    this.callNative('jsCallApp', {
      method: 'openPdf',
      pdfName,
      pdfUrl,
      shareFlag,
      type
    });
  }
  /**
   * 打开HTML链接
   */
  openHtml({
    htmlName,
    htmlUrl
  }) {
    this.callNative('jsCallApp', {
      method: 'openHtml',
      htmlName,
      htmlUrl
    });
  }
  /**
   * 打开URL链接,唤起浏览器
   */
  openUrl({
    url
  }) {
    this.callNative('jsCallApp', {
      method: 'openUrl',
      url
    });
  }
  /**
   * 拨打电话
   */
  callDail({
    number
  }) {
    this.callNative('jsCallApp', {
      method: 'callDail',
      number
    });
  }
  /**
   * toast弹窗
   *
   * @param object: { text: string } 弹窗文字
   */
  openToast({
    text
  }) {
    this.callNative('jsCallApp', {
      method: 'openToast',
      text
    });
  }
  /**
   * 打开新的webview
   * @param url string 跳转链接
   */
  openWebview({
    url
  }) {
    // console.log('进入openNewWebview方法',url);
    //  this.openToast({ 'text':  `进入openNewWebview方法,url是:${url}，稍后调用原生openNewWebview方法`})
    this.callNative('jsCallApp', {
      method: 'openNewWebview',
      url
    });
  }
  /**
   * 关闭页面方法
   */
  closeWebview() {
    this.callNative('jsCallApp', {
      method: 'closeWebview'
    });
  }
  // 跳转证券app原生搭桥
  goSecuritiesApp({
    code,
    isLogin,
    mobileAreaCode,
    mobilePhone
  }) {
    this.callNative('jsCallApp', {
      method: 'goSecuritiesApp',
      code,
      isLogin,
      mobileAreaCode,
      mobilePhone
    });
  }
  // 获取app原生数据
  queryData({
    key
  }) {
    return this.callNative('jsCallApp', {
      method: 'queryData',
      key
    });
  }
  // 获取原生传入的code
  getCode() {
    return this.callNative('jsCallApp', {
      method: 'getCode'
    });
  }
  //app头部颜色
  changeAppColor({
    color
  }) {
    return this.callNative('jsCallApp', {
      method: 'changeAppColor',
      color
    });
  }
  // 消息详情页给原生发送已读状态
  sendStatus({
    status
  }) {
    return this.callNative('jsCallApp', {
      method: 'sendStatus',
      status
    });
  }
  //获取国际化语言
  getLanguage() {
    return this.callNative('jsCallApp', {
      method: 'getLanguage'
    });
  }
  //唤起原生登录
  toLogin(params) {
    this.callNative('jsCallApp', {
      method: 'toLogin',
      code: params?.code
    });
  }
  // 通知app方法
  sendMessage({
    messageType,
    params
  }) {
    return this.callNative('jsCallApp', {
      method: 'sendMessage',
      messageType,
      params
    });
  }
  //账号被顶通知
  crownedOutLogin() {
    return this.callNative('jsCallApp', {
      method: 'crownedOutLogin'
    });
  }
  // 调用网络异常页面
  networkAnomaly() {
    return this.callNative('jsCallApp', {
      method: 'networkAnomaly',
      from: 'h5'
    });
  }
  //鏈接頁面刷新通知
  goReLoad() {
    return this.callNative('jsCallApp', {
      method: 'goReLoad',
      from: 'h5'
    });
  }
  // 去消息中心
  toMessageCenter() {
    return this.callNative('jsCallApp', {
      method: 'toMessageCenter'
    });
  }
  //通过app加密
  toEnctypTion(data) {
    return this.callNative('jsCallApp', {
      method: 'toEnctypTion',
      data
    });
  }
  //获取加密后的值
  toAcceptDate() {
    return this.callNative('jsCallApp', {
      method: 'toAcceptDate'
    });
  }
  //通过原生解密
  toDecrypt({
    data,
    iVOrg,
    keyOrg
  }) {
    return this.callNative('jsCallApp', {
      method: 'toDecrypt',
      data,
      iVOrg,
      keyOrg
    });
  }
  /*
   * 通过原生持久化数据
       * @key 存储的键值 建议以页面名作为key
       * @dataStr 存储的数据，string 类型 ->Json 字符串
   * 注意相同的key值存储调用多次，会覆盖之前的存储
   */
  saveData2Native({
    key,
    dataStr
  }) {
    return this.callNative('jsCallApp', {
      method: 'saveData2Native',
      key,
      dataStr
    });
  }
  /*
   * 通过原生获取持久化数据
   * @key 存储的键值
   * @once 是否一次性数据，取出后原生销毁key值的数据
   */
  getNativeSaveData({
    key,
    once
  }) {
    return this.callNative('jsCallApp', {
      method: 'getNativeSaveData',
      key,
      once
    });
  }
  /*
   * 通过key清理原生持久化数据
   * @key 存储的键值
   */
  cleanNativeData({
    key
  }) {
    return this.callNative('jsCallApp', {
      method: 'cleanNativeData',
      key
    });
  }
  /*
   * 强制更换host
   * @host host
   */
  changeHost({
    host
  }) {
    return this.callNative('jsCallApp', {
      method: 'changeHost',
      host
    });
  }
}
export default new JsBridge();