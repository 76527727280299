/*
 * @Author: f
 * @Date: 2022-12-05 16:28:45
 * @Description: file content
 * @LastEditors: yangyangli
 * @LastEditTime: 2023-07-27 15:28:19
 */
const zh_HK = {
  common: {
    my: '我的',
    add: '新增',
    submit: '提交',
    delete: '刪除',
    cancel: '取消',
    policy: '保單',
    HKD: '港幣',
    USD:'美元',
    RMB: '人民幣',
    Monthly: '月繳',
    Quarterly: '季繳',
    SemiAnnual: '半年繳',
    Annually: '年繳',
    Single: '躉繳',
    DirectBilling: '郵寄帳單',
    PremiumDepositAccount: '預繳保費護口',
    BankAccountAutopay: '銀行自動轉帳',
    AutopaySetupInProgressingle: '建立自動轉帳中',
    CreditCardAutopay: '信用卡轉帳',
    CreditCardAutopayToken: '信用卡轉帳Token',
    ElectronicDirectPayment: '電子直接付款',
    BankTransfer: '銀行轉帳',
    Cheque: '支票',
    PremiumPaidListingPremium: '滿期金繳付清單保費',
    Self: '本人',
    ParentChild: '父母/子女',
    Spouse: '配偶',
    EmployeeEmployer: '僱員/僱主  ',
    GrandParentGrandChild: '祖父母/孫子女',
    Others: '其他',
    Sibling: '兄弟姐妹',
    OwnEstate: '遺產繼承人',
    Trustee: '受托人',
    CreditorAndDebtor: '債權人及債務人',
    Charity: '慈善機構',
    PrimaryBeneficiary: '主要受益人',
    SecondaryBeneficiary: '次受益人',
  },
  lifeInsurance: {
    lifeHomePage: {
      title: '主頁',
      notice: '本頁由立橋人壽保險擁有並運營',
      guaranteeMoudle: '保障推薦',
      theme: '喜盈三年儲蓄守護保儲蓄守護保儲蓄 守護保',
      themeText: '平均每年保證回報高達平均每年保證回報回報',
      checkBtn: '立即查看',
      naveTitle: '尊享系列百萬醫療險全新來襲抓緊抓緊緊緊～',
      eventContent: '專為家庭定製給全家貼心的保障，專屬家庭醫生照顧全家健康，1對1專',
      goHome: '回首頁',
      activitiy: '活動與資訊',
      buyInsurance: '買保險',
      load: '拼命加載中～',
      empty:'暫無符合條件的內容',
    },
    policy: {
      policyList: '我的保單',
      tipsOne: '您尚未連結立橋人壽保險相關賬戶，請按“去',
      tipsTwo: '連結”會去到立橋金服相關頁面為您連結相關賬戶。',
      golink: '去連結',
      insuranceNum: '保單數',
      insuranceNumValue: '6張',
      Insured: '受保人',
      InsuredValue: '3人',
      cardName: '立智保',
      warrantyPeriod: '保障期限',
      no: '保單編號',
      policyFiles: '保單文件',
      person: '受保人',
      PolicyDetails: '保單詳情',
      PolicyNo: '保單編號',
      Claim: '理賠',
      Policymodify: '保單更改',
      SettleRenewalPremium: '繳付續期保費',
      BasicInformation: '基本資料',
      PaymentInformation: '付款資料',
      CoverageInformation: '保障資料',
      LevyPayment: '保費徵費',
      PolicyValuesInformation: '保單價值資料',
      MaturityPayment: '期滿金',
      BeneficiaryInformation: '受益人資料',
      PolicyDocument: '保單文件',
      BasicPlan: '基本計劃',
      PolicyStatus: '保單狀況',
      PolicyCurrency: '保單貨幣',
      NameofLifeInsured: '受保人姓名',
      NameofPolicyowner: '權益人姓名',
      PolicyDate: '保單日期',
      CorrespondenceAddress: '通訊地址',
      MobileNo: '流動電話號碼',
      OfficeTel: '公司電話號碼',
      HomeTel: '住宅電話號碼',
      Email: '電郵地址',
      InsuranceConsultant: '保險顧問',
      InsuranceConsultantCode: '保險顧問編號',
      ContactTelephoneNo: '聯絡電話',
      PaidToDate: '保費繳至日期',
      PaymentMode: '保費繳付頻次',
      ModalPremium: '每期保單保費',
      PerPremiumLevy: '每期保費徵費',
      PaymentInformationTips: '*如上述保單已有到期保費，或即將生效的保單更改項目，所顯示之「每期保單保費」為下一期的每期保費。',
      PremiumPaymentMethod: '保費繳付方式',
      AutomaticPremiumLoan: '自動轉賬賬戶',
      LevyPaymentUS: '保費徵費（美元）',
      LevyPaid: '已徵收（自上一個保單週年日起）',
      TotalLevyPaid: '已徵收的總和',
      OutstandingLevy: '欠繳（尚未逞報至保險業監管局）',
      TotalOutstanding: '欠繳 (欠繳的總和)',
      Status: '狀況',
      PolicyCoverageTill: '保障日期至',
      PlanLevel: '計劃級別',
      AsofDate: '截至日期',
      GuaranteedCashValue: '保證現金價值',
      PremiumDepositAccount: '預繳保費戶口結餘',
      FuturePremiumDeposit: '備用保費',
      PolicyLoanInterest: '保單貸款及利息',
      MaturityPaymentInstruction: '期滿金付款指示',
      BankAccount: '銀行帳號',
      NameofBeneficiary: '受益人姓名',
      RelationshipwithLifeInsured: '與受保人關系',
      PercentageofEntitlement: '領取百分比',
      TypeofBeneficiary: '受益人級別',
      ePolicy: '電子保單',
      eNotification: '電子通知書',
      PolicyIllustration: '保單價值預期演示',
      TotalOutstandingLevy: '欠繳 (欠繳的總和)',
      AutomaticPremiumPayment: '自動保費墊繳',
      AccumulatedDividendsInterest: '積存紅利及利息',
      TerminalDividend: '終期紅利',
      AccumulatedGuaranteedCashCouponsInterest: '積存可支取保證現金及利息',
      SumOfInsured: '投保額',
      SpecialDividend: '特別紅利 ',
      AutomaticPremiumLoanInterest: '自動保費墊繳及利息',
      CreditCardNo: '信用卡號碼',
      AnnuityPaymentMode: '年金發放方式',
      AnnuityAmount: '每期年金金額',
      BasicPlanFringeBenefitProtection: '基本計劃/附加利益保障',
      AnnuityStartDate: '首期年金發放日期',
      ReversionaryBonus: '歸原紅利',
      VHISCertificationNumber: '自願醫保認可產品編號',
      HomeInsurancePolicyDetails: '家居保險 保單詳情',
      Productname: '產品名稱',
      PolicyInfo: '保單資料',
      Policyholder: '保單持有人',
      HomeDetails: '家居資料',
      PolicyCover: '保障詳情',
      OptionalCover: '自選保障',
      TotalPremium: '保費總額',
      Plan: '計劃',
      StartDate: '生效日期',
      EndDate: '屆滿日期',
      PolicyholderInformation: '保單持有人資料',
      Name: '姓名',
      Gender: '性別',
      MobileNumber852: '手提電話號碼 (+852)',
      RiskLocation: '受保地點',
      FloorAreaSqFt: '面積 (平方呎)',
      YearofBuilt: '落成年份',
      PropertyType: '建築物類型',
      Occupancy: '佔用性質',
      Cover: '保障',
      SumInsured: '保額',
      PolicyExcess: '自選保障',
      HealthInsurancePolicyDetails: '醫療保險 保單詳情',
      extraCover: '額外保障',
      PolicyPlan: '保險計劃',
      HKIDNumber: '香港身份証號碼',
      DateofBirth: '出生日期',
      MaritalStatus: '婚姻狀況',
      Address: '地址',
      InsuredDetails: '受保人資料',
      PolicyExcessExtra: '保單自負額',
      Refertopolicyschedule: '參考保單文件',
      Installment: '分期付款',
      MonthlyPremium: '每月保費',
      SinglePayment: '壹次性付款',
      TravelInsurancePolicyDetails: '旅遊保險 保單詳情',
      PolicyType: '保單類別',
      PlanCode: '計劃類型',
      Zone: '地區範圍',
      PolicyholderInsuredPersonInformation: '保單持有人(受保人)資料',
      TravellerInformation: '同行人士資料',
      PrivateMotorInsurancePolicyDetails: '私家車保險 保單詳情',
      DriverDetails: '司機資料',
      VehicleDetails: '車輛資料',
      Occupation: '職業',
      PolicyholderMainDriverInformation: '保單持有人(主要記名司機)資料',
      MainDriverInformation: '主要記名司機資料',
      DrivingOffencePointsinthelast2years: '在過去的2年內曾有違例駕駛扣分紀錄?',
      NoClaimDiscountNCD: '無賠償記錄折扣(NCD)',
      DrivingExperienceYears: '駕駛經驗(年)',
      NamedDriversInformation: '記名司機資料',
      VehicleInformation: '車輛資料',
      VehicleRegistrationMark: '車輛登記號碼',
      ChassisNumber: '車身底盤號碼',
      EngineNumber: '引擎號碼',
      HirePurchaseOwner: '財務公司名稱',
      HirePurchaseValue: '貸款金額',
      YearofManufacture: '出廠年份',
      CarMake: '車廠名稱',
      CarModel: '型號',
      CarUsage: '車輛用途',
      statusInvalid: '已失效',
      statusValid: '生效中',
      statusValiding: '投保中',
      insured:'受保人',
      coveredVehicleType: '受保車型',
      premDueDate: '保費到期日',
      annualPremium: '年繳保費',
      MoreThan5Years: '5年以上',
      dialogTitle: '提示',
      dialogContent: '保單詳情僅對有效保單開放，其他狀態暫不支持查看',
      dialogBtn: '我知道了',
      noFile: '未找到保單文件',
      noFile1: '未找到電子保單',
      noFile2: '此保單沒有該類別下可供查閱之通知書',
      noFile3: '未找到保單價值預期演示',
      dialogContentWBT06: '該產品不支持查看詳情！',
      sure: '確定',
      AnniversaryNotice: '周年通知書',
      PreservationNotice: '保全通知書',
      RenewalNotice: '續期通知書',
      ClaimNotice: '理賠通知書',
      FinancialReceipt: '財務收據',
      noLisDocIDDes: '該保單暫無此類型文件！',
      loadFail: '文件加載失敗，請稍後再試！'
    }
  },
  wealthInsurance: {
    wealthHomePage: {
      notice: '本頁由立橋保險擁有並運營',
      lifeClass: '人壽',
      lifeClassTitle: '尊享系列百萬醫療險全新來襲抓緊抓緊緊緊～',
      lifeClassContent: '專為家庭定製給全家貼心的保障，專屬家庭醫生照顧全家健康，1對1專',
      DrivingClass: '駕駛',
      OutTravelClass: '外遊',
      ElectricBicycleClass: '電單車',
      HealthyClass: '健康',
      load: '拼命加載中～',
      empty: '暫無符合條件的內容',
      discountsPromotions: '優惠與推廣'
    },
    wealthPolicy: {
      policyList: '我的保單',
      goHome: '回首頁',
      tipsOne: '您尚未連結立橋保險相關賬戶，請按“去',
      tipsTwo: '連結”會去到立橋金服相關頁面為您連結相關賬戶。',
      golink: '去連結',
      tips:'提示',
      details:'本保單經由中介安排，如有查詢，',
      contact:'請聯絡你的中介人',
      confirm:'確認'
    },
    policy:{
      detailTitle: '保單資料',
      productName: '產品名稱',
      no: '保單編號',
      effectDate: '生效日期',
      overdueData: '屆滿日期',
      oneoffPayment: '一次性付款'
    }
  },
  my: {
    contactUs: {
      navTitle: '聯繫我們',
      canHelp: '有什麼可以幫您',
      solveProblem: '專屬客服人員解決您的問題'
    },
    policy: {
      navTitle: '私隱政策與保安',
      privacyPolicy: '隱私政策',
      siyinPolicy: '私隱政策',
      personalPrivacyPolicy: '個人資料私隱政策'
    },
    collection: {
      navTitle: '個人信息收集聲明'
    },
    userStatement: '使用條款',
    networkInfoTitle: '網絡安全須知',
    disclaimerTitle: '免責聲明',
    wellLinkProTitle: '立橋國際證券有限公司',
wellLinkTitle: '立橋證券有限公司',
    aboutInfo: {
      about: '關於',
      aboutWL: '關於立橋',
      aboutWLInsurance: '關於立橋保險',
      aboutWLLife: '關於立橋人壽',
      versionInfo: '版本信息',
      aboutTip:'© 2024立橋金融集團版權所有不得轉載'
    },
    title: '聯絡我們',
    address: '地址',
    workTime: '辦公時間',
    serviceTel: '客戶服務熱線',
    email: '電郵',
    net: '網址',
    hi: 'Hi～',
    user: '親愛的立橋用戶',
    backToHome: '回首頁',
    canHelp: '有什麼可以幫您',
    solveProblem: '專屬客服人員解決您的問題',
    lifeInsurance: '人壽保險',
    normalInsurance: '一般保險',
    wellLink: '立橋證券有限公司',
    article: '網站使用條款',
    wealthManage: {
      navTitle: '財富管理中心',
      bgTitle1: '立橋人壽',
      bgTitle2: '財富管理中心',
      bgTip: '為客戶提供貼心保險諮詢服务。',
      WLLife: '立橋人壽',
      branchInfo: '分店資訊'
    },
    msgCenter: {
      navTitle: '消息中心',
      msgList: '消息列表',
      msgDetails: '消息詳情'
    },
    myOfLife: {
      effectPolicyNum: '有效保單數',
      myIntegral: '我的積分',
      zhang: '張',
      ren: '人',
    }
  },
  separateLink: {
    lifeInsuranceTitle: '人壽保險保單連結',
    insuranceTitle: '一般保險保單連結',
    securitiesTitle: '證券賬戶連結',
    registerApp: '開通立橋國際證券APP賬號',
    serviceAuthorization: '服務授權條款',
    nextBtn: '下一步',
    previousBtn: '上一步',
    warn: '請選擇您要連結的賬戶',
    linkLists: {
      title: '連結賬戶',
      titleDes: '您可連結立橋金融集團旗下各公司賬戶',
      lifeInsurance: '立橋人壽保險',
      insurance: '立橋保險',
      securities: '立橋國際證券',
      noConnected: '未連結',
      insConnected: '已連結，可繼續連結其他賬戶',
      securitiesConnected: '已連結，可解除連結后連結其他賬戶',
      goLink: '去連結'
    },
    basicInformation: {
      titleDes: '請輸入你的基本個人資料讓我們確認身份',
      enLastName: '英文姓氏',
      enName: '英文名字',
      enNameGeneral:'英文姓名',
      birthday: '出生日期',
      gender: '性別',
      select: '请選擇',
      input: '請輸入',
      warnEnLastName: '請輸入英文姓氏',
      warnEnName: '請輸入英文名字',
      warnEnNameGeneral: '請輸入英文姓名',
      dateChoose: '請選擇',
      warnBirthDay: '請選擇出生日期',
      warnGender: '請選擇性別'
    },
    idLink: {
      titleDes: '請輸入購買保單時所使用的身份證明文件',
      titleDes1: '請輸入開戶時所使用的身份證明文件',
      HKIdCard: '香港身份證',
      PRC: '中華人民共和國',
      PRCIdCard: '居民身份證',
      passports: '各國護照',
      MacaoIdCard: '澳門身份證',
      idCardTitle: '證件號碼（省去符號）',
      placeholder: '例如號碼A123456(7),請輸入A1234567',
      placeholderElse: '請輸入您的證件號碼'
    },
    policyList: {
      titleDes: '親愛的客戶，已為您找到以下保單！',
      titleNoDate: '親愛的客戶，我們未能找到您的保單！',
      securitiesDes: '若您想更換其他手機號連結證券APP，請修改手機號',
      lifeDes: '根據您輸入的資料，我們找不到人壽保險保單。請檢查資料是否正確（例如：選擇的證件是否與買單時提供的一致），閣下可返回前頁進行修改',
      genDes: '根據您輸入的資料，我們找不到一般保險保單。請檢查資料是否正確（例如：選擇的證件是否與買單時提供的一致）。閣下可返回前頁進行修改。',
      backBtn: '返回',
      saveBtn: '保存',
      agree: '我已閱讀並同意',
      agreeServe: '《服務授權條款》',
      agreement: '《服務協議》',
      agreeClause: '《私隱保護政策》',
      and: '和',
      linkBtn: '連結',
      plaPhone: '請輸入手機號',
      plaCode: '請輸入驗證碼',
      verificationCode: '驗證碼',
      warnVerification: '請輸入驗證碼',
      warnVerificationLength: '請輸入正確的驗證碼',
      getVerificationCode: '獲取驗證碼',
      recode: '重新獲取',
      please: '請選擇',
      pleaseCard: '請選擇護照國家',
      endBtn: '結束',
      finishBtn: '完成',
      lifeConnected: '立橋人壽保險已連結',
      generalConnected: '立橋保險（一般保險）已連結',
      securityConnected: '立橋國際證券已連結',
      securitySuccess: '按完成開始體驗立橋e家',
      tips: '提示',
      unregistered: '未找到立橋國際證券賬戶',
      unregistered1: '即將為您跳轉註冊',
      sureBtn: '確認',
      linkEd: '已連結',
      linkEdText: '您的證券APP賬戶已連結成功，若需要連結其他賬戶，請按繼續解除連結後再綁定新賬戶',
      continueBtn: '繼續',
      registerText: '請填寫以下信息，以供註冊',
      registerTextPhone: '立橋國際證券登入賬戶',
      registerVersCode: '您短訊中的驗證碼',
      noMore: '沒有更多了',
      callText: '如有疑問，請致電 ',
      unLinkedSuccess: '解除證券帳戶連結成功！',
      to: '及',
      warnPhone: '請輸入正確的手機號碼',
      warnIdNum: '您未輸入證件號碼',
      errorIdNum: '請輸入正確的證件號碼',
      verCode: '請輸入完整驗證碼',
      radioRead: '請閱讀並勾選對應協議',
      lifeCodeTips1: "親愛的客戶，根據您提供的資料",
      lifeCodeTips2: "為您找到以下與保單相關的電話。",
      selectTel: '請選擇任一電話號碼獲取驗證碼',
      code: '輸入短訊中的驗證碼',
      linkTips: '如以上電話號碼不正確，請',
      call: '聯繫我們',
      NoPhone: '親愛的用戶，根據您提供的資料發現您的保單尚未預留移動電話，請',
      NoPhoneTips: '，補充移動電話後再次嘗試連結您的保單！',
    },
  },

  //保險產品
  insuranceProducts: {
    title: '保險產品',
    name: '登記即享免費意外保障',
    insuranceIntroduce: '新客戶禮遇新客戶禮遇新客戶禮遇新客戶禮遇新客戶禮遇新客戶禮遇'
  },
  InsuranceAgreement: {
    title: '立橋人壽保險',
    tipsOne: '您即將瀏覽的保險產品由立橋人壽保險有限公司（「立橋人壽」）提供，若您對於下一個頁面所包含的資訊及內容有任何疑問，',
    link: '可聯緊「立橋人壽」',
    tipsTwo: '進行咨詢。',
    open: '即將為您打開手機瀏覽器跳轉至對應產品頁面。',
    btnContent: '我已知曉，並繼續',
    linkMethods: '聯緊電話',
    phone: '呼叫 +85228307500',
    cancelBtn: '取消'
  },
  wealthInsuranceAgreement: {
    title: '立橋保險',
    tipsOne: '您即將瀏覽的保險產品由立橋保險有限公司([立橋保險])提供，若您對於下一個頁面所包含的資訊及內容有任何疑問，',
    link: '可聯緊[立橋保險]',
    tipsTwo: '進行咨詢。',
    open: ' 即將為您打開手機瀏覽器跳轉至對應產品頁面。',
    btnContent: '我已知曉，並繼續',
    linkMethods: '聯緊電話',
    phone: '呼叫 +8522848888',
    cancelBtn: '取消'
  },
  iconList: {
    title: '服務',
    titleDiscount: '優惠及產品',
    titleLifeInsurance: '人壽保險',
    titleInsurance: '一般保險',
    titleSecurities: '證券',
    titleSyetemAndMessage: '系統與消息'
  },
  discountZone:{
    title: '優惠專區'
  },
  discountTabData:   [{
    src: './image/discount/itemfirst.png',
    heading: '尊享系列百萬醫療險全新來襲抓緊抓緊緊緊～',
    subheading: '專為家庭定製給全家貼心的保障，專屬家庭醫生照顧全家健康，1對1專'
  },{
    src: './image/discount/itemfirst.png',
    heading: '新來襲抓緊抓緊緊緊～',
    subheading: '專為家庭定製給全家貼心的保障，專屬家庭醫生照顧全家健康，1對1專'
  },{
    src: './image/discount/itemfirst.png',
    heading: '百萬醫療險全新來襲抓緊抓緊緊緊～',
    subheading: '專為家庭定製給全家貼心的保障，專屬家庭醫生照顧全家健康，1對1專'
  },{
    src: './image/discount/itemfirst.png',
    heading: '系列百萬醫療險全新來襲抓緊抓緊緊緊～',
    subheading: '專為家庭定製給全家貼心的保障，專屬家庭醫生照顧全家健康，1對1專'
  }],
  notFound: {
    pageLoading: '拼命加載中～',
    noConditionTips: '暫無符合條件的內容',
    networkError: '網絡異常，請刷新重試',
    noLogin: '暫未登錄， ',
    toLogin:'去登錄'
  }
}

export default zh_HK
