/*
 * @Author: f
 * @Date: 2022-12-05 14:15:27
 * @Description: file content
 * @LastEditors: f
 * @LastEditTime: 2023-02-28 14:45:31
 */
import { defineStore } from 'pinia'

export const insuranceLinkStore = defineStore('insuranceLink', {
  state: () => {
    return {
      baseInfo: {},
      cate: '',
      passport: '',
      idType: '01',
      policyList: {},
      radioCode: '',
      isAgree: '',
      birthday:''
    }
  },
  getters: {
    getBaseInfo: (state) => {
      return state.baseInfo
    },
    getCate: (state) => {
      return state.cate
    },
    getPassport: (state) => {
      return state.passport
    },
    getIdType: (state) => {
      return state.idType
    },
    getRadioCode: (state) => {
      return state.radioCode
    },
    getAgree: (state:{isAgree:string}):string => {
      return state.isAgree
    },
     getBirthday: (state) => {
       return state.birthday
    },
  },
  actions: {
    setIsAgree(value: string) {
  this.isAgree = value
},
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'baseInfo',//设置存储的key
        storage: localStorage,//表示存储在localStorage
      }
    ]

  }
})
